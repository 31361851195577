import React, { useContext, useHistory, useState, useEffect } from "react";
import { Menu, Icon, Divider, Progress, Drawer, Badge } from "antd";
import { FileContext } from "../Context";
import InfoSidebar from "./InfoSidebar";

import { Link, withRouter } from "react-router-dom";
const Sidebar = (props) => {
  const value = useContext(FileContext);
  // const history = useHistory();
  const [visibleInfoDraw, setVisibleInfoDraw] = useState(false);

  useEffect(() => {
    if (value.spaceUsedObject !== null) {
      value.setTotalUsedSize(
        (value.spaceUsedObject.storage_size +
          value.spaceUsedObject.trash_size) /
          10 ** 9
      );
    }

    // console.log(((total / 10 ** 6) * 100) / 10000);
    // console.log(((total / 10 ** 9) * 100) / 10);
    // console.log(value.storageData);
  }, [value.spaceUsedObject]);

  // useEffect(() => {
  //   let tempArr = [];
  //   value.storageData.map(item => {
  //     tempArr.push({
  //       name: item.type,
  //       value: (((item.size / 10 ** 9) * 100) / 10).toFixed(2)
  //     });
  //   });
  //   console.log("tempArr", tempArr);
  //   value.setCategoryPercentage([...tempArr]);
  // }, []);

  return (
    <div
      style={{
        backgroundColor: "#fcfcff",
        // backgroundColor:"#E9EBEE"
        borderRight: "1px solid #F5F5F5",
        // position: "fixed",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          height: "99vh",
        }}
      >
        <div className="top-menus">
          <div
            // className="shadow"
            style={{
              backgroundColor: "#B3315B",
              paddingTop: "20px",
              paddingBottom: "20px",
              paddingLeft: "10px",
              display: window.innerWidth > 600 ? "flex" : "none",
              alignItems: "center",
              justifyContent: "space-between",

              // flexDirection: "column"
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={require("../img/logo_white2.svg")}
                alt=""
                width="150px"
              />
              {/* <span
                style={{ fontSize: "20px", fontWeight: "600", color: "white" }}
              >
                &nbsp;&nbsp; Brain
              </span> */}
            </div>
            <Icon
              onClick={(e) => {
                localStorage.clear();
                window.location.reload();
              }}
              type="poweroff"
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                color: "white",
                paddingRight: "15px",
                cursor: "pointer",
              }}
            />
          </div>

          <div
            className="divmenuhover"
            onClick={(e) => {
              value.setPath("root/");
              value.setPathArray(["root"]);
              props.history.push("/");
            }}
            style={{
              display: "flex",
              alignItems: "center",
              paddingLeft: "40px",
              justifyContent: "left",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {/* <i
                class="fa fa-folder"
                style={{ fontSize: "20px", color: "#757575" }}
              ></i> */}{" "}
              <img
                src={require("../img/folder.svg")}
                style={{ marginLeft: "-5px", width: "26px" }}
              ></img>
              <div style={{ fontWeight: "600", color: "#9E9E9E" }}>
                &nbsp;&nbsp;&nbsp;&nbsp;My Drive
              </div>
            </div>
          </div>

          <Divider dashed style={{ margin: "0px 0px" }} />
          {/* <div
            className="divmenuhover"
            onClick={e => value.setPath([value.path[0]])}
            style={{
              display: "flex",
              alignItems: "center",
              paddingLeft: "40px",
              justifyContent: "left"
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <i
                class="fas fa-file"
                style={{ fontSize: "20px", color: "#757575" }}
              ></i>
              <span
                style={{
                  fontWeight: "600",
                  color: "#9E9E9E",
                  marginLeft: "-2.5px"
                }}
              >
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Recent Files
              </span>
            </div>
          </div>

          <Divider dashed style={{ margin: "0px 0px" }} /> */}
          {/* <div
            className="divmenuhover"
            onClick={e => value.setPath([value.path[0]])}
            style={{
              display: "flex",
              alignItems: "center",
              paddingLeft: "40px",
              justifyContent: "left"
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <i
                class="fas fa-star"
                style={{
                  fontSize: "20px",
                  color: "#757575",
                  marginLeft: "-1px"
                }}
              ></i>

              <span
                style={{
                  fontWeight: "600",
                  color: "#9E9E9E",
                  marginLeft: "-5px"
                }}
              >
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Starred
              </span>
            </div>
          </div>
          
          <Divider dashed style={{ margin: "0px 0px" }} /> */}

          {/* <Link to="/shared-files">
            <div
              className="divmenuhover"
              onClick={(e) => value.setPath([value.path[0]])}
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "42px",
                justifyContent: "left",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
               
                <img
                  src={require("../img/share.svg")}
                  style={{ marginLeft: "-5px", width: "24px" }}
                ></img>
                <span
                  style={{
                    fontWeight: "600",
                    color: "#9E9E9E",
                    marginLeft: "-1.5px",
                  }}
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Shared Files
                </span>
              </div>
            </div>
          </Link> */}
          <Link to="/subdomain">
            <div
              className="divmenuhover"
              onClick={(e) => value.setPath([value.path[0]])}
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "42px",
                justifyContent: "left",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                {/* <i
                  class="fa fa-share-alt"
                  style={{
                    fontSize: "20px",
                    color: "#757575",
                    marginLeft: "-2px"
                  }}
                ></i> */}
                <img
                  src={require("../img/share.svg")}
                  style={{ marginLeft: "-5px", width: "24px" }}
                ></img>
                <span
                  style={{
                    fontWeight: "600",
                    color: "#9E9E9E",
                    marginLeft: "-1.5px",
                  }}
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Share
                </span>
              </div>
            </div>
          </Link>
          <Divider dashed style={{ margin: "0px 0px" }} />
          {/* <Link to="/spaces">
            <div
              className="divmenuhover"
              onClick={e => value.setPath([value.path[0]])}
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "40px",
                justifyContent: "left"
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={require("../img/cloud1.svg")}
                  style={{ marginLeft: "-5px", width: "24px" }}
                ></img>

                <span
                  style={{
                    fontWeight: "600",
                    color: "#9E9E9E",
                    marginLeft: "-3px",
                    textAlign: "left"
                  }}
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Spaces&nbsp;&nbsp;
                  <span style={{ fontSize: "11px" }}>(Soon...)</span>
                </span>
              </div>
            </div>
          </Link>
          <Divider dashed style={{ margin: "0px 0px" }} /> */}

          <Link to="/bookmark">
            <div
              className="divmenuhover"
              onClick={(e) => value.setPath([value.path[0]])}
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "42px",
                justifyContent: "left",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                {/* <i
                  class="fa fa-bookmark"
                  style={{
                    fontSize: "20px",
                    color: "#757575",
                    marginLeft: "-2px"
                  }}
                ></i> */}
                <img
                  src={require("../img/bookmarkicon.svg")}
                  style={{ marginLeft: "-5px", width: "24px" }}
                ></img>
                <span
                  style={{
                    fontWeight: "600",
                    color: "#9E9E9E",
                    marginLeft: "-1.5px",
                  }}
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Bookmark
                </span>
              </div>
            </div>
          </Link>

          <Divider dashed style={{ margin: "0px 0px" }} />
          <Link to="/trash">
            <div
              className="divmenuhover"
              onClick={(e) => value.setPath([value.path[0]])}
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "42px",
                justifyContent: "left",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                {/* <i
                  class="fa fa-trash"
                  style={{
                    fontSize: "20px",
                    color: "#757575",
                    marginLeft: "-2px"
                  }}
                ></i> */}
                <img
                  src={require("../img/trash.svg")}
                  style={{ marginLeft: "-5px", width: "24px" }}
                ></img>

                <span
                  style={{
                    fontWeight: "600",
                    color: "#9E9E9E",
                    marginLeft: "-1.5px",
                  }}
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Trash{" "}
                  {value.spaceUsedObject !== null &&
                  value.spaceUsedObject.trash_size > 0 ? (
                    <Badge color="#f50" />
                  ) : (
                    ""
                  )}
                </span>
              </div>
            </div>
          </Link>

          <Divider dashed style={{ margin: "0px 0px" }} />

          {/* <Link to="/createCourse">
            <div
              className="divmenuhover"
              onClick={(e) => value.setPath([value.path[0]])}
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "42px",
                justifyContent: "left",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                
                <img
                  src={require("../img/newcourse.svg")}
                  style={{ marginLeft: "-5px", width: "24px" }}
                ></img>

                <span
                  style={{
                    fontWeight: "600",
                    color: "#9E9E9E",
                    marginLeft: "-1.5px",
                  }}
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;New Course
                </span>
              </div>
            </div>
          </Link>
          <Divider dashed style={{ margin: "0px 0px" }} />
          <Link to="/managecourse">
            <div
              className="divmenuhover"
              onClick={(e) => value.setPath([value.path[0]])}
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "42px",
                justifyContent: "left",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                
                <img
                  src={require("../img/course.svg")}
                  style={{ marginLeft: "-5px", width: "25px" }}
                ></img>

                <span
                  style={{
                    fontWeight: "600",
                    color: "#9E9E9E",
                    marginLeft: "-1.5px",
                  }}
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;Manage Courses
                </span>
              </div>
            </div>
          </Link>
          <Divider dashed style={{ margin: "0px 0px" }} />
          <Link to="/createPostVideo">
            <div
              className="divmenuhover"
              onClick={(e) => value.setPath([value.path[0]])}
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "42px",
                justifyContent: "left",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                
                <img
                  src={require("../img/post_video.svg")}
                  style={{ marginLeft: "-5px", width: "25px" }}
                ></img>

                <span
                  style={{
                    fontWeight: "600",
                    color: "#9E9E9E",
                    marginLeft: "-1.5px",
                  }}
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;Profile A Video
                </span>
              </div>
            </div>
          </Link>
          <Divider dashed style={{ margin: "0px 0px" }} /> */}
        </div>

        <div className="bottom-info" style={{ marginBottom: "50px" }}>
          <Divider dashed style={{ margin: "0px 0px" }} />

          <div
            className="divmenuhover"
            style={{
              padding: "20px 20px",
              textAlign: "center",
              cursor: "pointer",
            }}
            onClick={(e) => setVisibleInfoDraw(true)}
          >
            <Progress
              strokeColor="#B3315B"
              strokeWidth={10}
              percent={(
                (value.totalUsedSize * 100) /
                (value.maxStorageLimit / 10 ** 9)
              ).toFixed(2)}
              showInfo={false}
            />
            <small>
              You have{" "}
              <span style={{ fontWeight: "bold", color: "#B3315B" }}>
                {(
                  value.maxStorageLimit / 10 ** 9 -
                  value.totalUsedSize
                ).toFixed(2)}
                GB
              </span>{" "}
              of storage Left
            </small>
          </div>
        </div>
      </div>
      <Drawer
        width={450}
        destroyOnClose
        placement="right"
        closable={true}
        onClose={(e) => setVisibleInfoDraw(false)}
        visible={visibleInfoDraw}
      >
        <InfoSidebar data={value.storageData} />
      </Drawer>
    </div>
  );
};

export default withRouter(Sidebar);
