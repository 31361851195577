import React, { useState, useContext, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Input,
  Icon,
  Card,
  Divider,
  Alert,
  Menu,
  Dropdown,
  Modal,
  Button,
  message,
  Popover,
  Tooltip,
  Progress,
  Empty,
  Typography,
  Tabs,
  Result,
  Avatar,
} from "antd";
import { FileContext } from "../Context";
import Sidebar from "./Sidebar";
// import blurbg from "../img/blurry.jpeg";
import blurbg from "../img/gradientbg.jpg";
import S3 from "aws-s3";
import axios from "axios";

const { TextArea } = Input;
const { Meta } = Card;

const CreateCourse = () => {
  const { fileFolderData, pulseTradingFloorApiUrl } = useContext(FileContext);
  const [courseTitle, setCourseTitle] = useState("");
  const [courseDesc, setCourseDesc] = useState("");
  const [courseCover, setCourseCover] = useState(null);

  const [chapterTitle, setChapterTitle] = useState("");
  const [chapterDesc, setChapterDesc] = useState("");

  const [query, setQuery] = useState("");
  const [step1, setStep1] = useState(true);
  //   const [step2, setStep2] = useState(false);
  const [courseArr, setCourseArr] = useState([]);
  const [finalArr, setFinalArr] = useState([]);

  const [visibleEditChapter, setVisibleEditChapter] = useState(false);
  //   const chapterTitleRef = useRef("");
  //   const chapterDescRef = useRef("");
  const [loading, setLoading] = useState(false);
  const [courseCoverS3url, setCourseCoverS3url] = useState("");
  const [selectedChapter, setSelectedChapter] = useState({});
  const [chapterCoverS3url, setChapterCoverS3url] = useState("");
  const [thumbnail, setThumbnail] = useState("");

  const CoverConfig = {
    bucketName: "gxsocialprofilecover",
    dirName: "coursecover",
    region: "us-east-2",
    accessKeyId: "AKIA4HKGLPLP7H73PB57",
    secretAccessKey: "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",
  };

  let filteredFiles = fileFolderData.filter((item) => {
    const lowquery = query.toLowerCase();
    return (
      (item.path + item.name).toLowerCase().indexOf(lowquery) >= 0 &&
      !item.folder
    );
  });

  useEffect(() => {
    console.log(finalArr);
  }, [finalArr]);

  useEffect(() => {
    setChapterTitle("");
    setChapterDesc("");
  }, [visibleEditChapter]);

  const addtoCourse = (item) => {
    console.log(item);
    if (courseArr.length > 0) {
      if (courseArr.find((o) => o.name === item.name)) {
        console.log(courseArr);
        var index = courseArr.findIndex((x) => x.name === item.name);
        const newArr = courseArr.filter((_, i) => i !== index);
        setCourseArr(newArr);
        console.log(index, newArr);
      } else {
        setCourseArr([...courseArr, item]);
      }
    } else {
      setCourseArr([...courseArr, item]);
    }
  };

  const showIcon = (item) => {
    if (courseArr.length > 0) {
      if (courseArr.find((o) => o.name === item.name)) {
        return (
          <Icon
            type="minus"
            style={{
              color: "red",
              marginTop: "5px",
              marginRight: "10px",
              // fontSize: "25px"
            }}
          />
        );
      } else {
        return (
          <Icon
            type="plus"
            style={{
              color: "#1D87D0",
              marginTop: "5px",
              marginRight: "10px",
              // fontSize: "25px"
            }}
          />
        );
      }
    } else {
      return (
        <Icon
          type="plus"
          style={{
            color: "#1D87D0",
            marginTop: "5px",
            marginRight: "10px",
            // fontSize: "25px"
          }}
        />
      );
    }
  };

  const updateChapter = (courseTitle, courseDesc) => {
    if (courseTitle !== "" && courseDesc !== "") {
      courseArr[selectedChapter.index].title = courseTitle;
      courseArr[selectedChapter.index].description = courseDesc;
      courseArr[selectedChapter.index].cover_url = chapterCoverS3url;
      setChapterTitle("");
      setChapterDesc("");
      setThumbnail("");
      setChapterCoverS3url("");
      setVisibleEditChapter(false);
    } else {
      message.destroy();
      message.error("Please fill all the fields.");
    }
  };

  const uploadCourseCover = async (selectedImg) => {
    setLoading(true);
    const S3Client = new S3(CoverConfig);
    // let uploaded = await S3Client.uploadFile(selectedImg);
    let uploaded_data;
    try {
      message.loading("uploading...");
      uploaded_data = await S3Client.uploadFile(selectedImg, selectedImg.name);
      message.success("File Upload Success");
      setCourseCoverS3url(uploaded_data.location);
      setLoading(false);
      message.destroy();
    } catch (e) {
      console.log(e);
      message.destroy();
      message.error("File Upload Failed", 2);
    }
  };

  const onCoverImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        setCourseCover(e.target.result);
        // this.setState({ image: e.target.result });
      };
      reader.readAsDataURL(event.target.files[0]);
      uploadCourseCover(event.target.files[0]);
    }
  };

  const onChapterImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        setThumbnail(e.target.result);
        // this.setState({ image: e.target.result });
      };
      reader.readAsDataURL(event.target.files[0]);
      uploadChapterImage(event.target.files[0]);
    }
  };

  const uploadChapterImage = async (selectedImg) => {
    setLoading(true);
    const S3Client = new S3(CoverConfig);
    // let uploaded = await S3Client.uploadFile(selectedImg);
    let uploaded_data;
    try {
      //   message.loading("uploading...");
      uploaded_data = await S3Client.uploadFile(selectedImg, selectedImg.name);
      // message.success("File Upload Success");
      let url =
        "https://djoplec97gdag.cloudfront.net" +
        uploaded_data.location.substring(55, uploaded_data.location.length);
      setChapterCoverS3url(url);

      setLoading(false);
      message.destroy();
    } catch (e) {
      console.log(e);
      message.destroy();
      message.error("File Upload Failed", 2);
    }
  };

  return (
    <>
      <Row style={{ minHeight: "100vh", position: "relative" }}>
        <Col
          className="shadow"
          //   height="100vh"
          xs={24}
          sm={4}
          style={{
            backgroundColor: "white",
            zIndex: "1",
            position: "fixed",
            left: 0,
            top: 0,
            height: "100vh",
          }}
        >
          <Sidebar />
        </Col>
        <Col
          xs={24}
          sm={20}
          offset={window.innerWidth > 600 ? 4 : 0}
          style={{
            backgroundColor: "#FFFFFF",
            height: "100vh",
          }}
        >
          {step1 ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
              }}
            >
              <div>
                {/* <Avatar
                className="shadow"
                style={{ marginBottom: "30px" }}
                size={200}
                src="https://lagunita.stanford.edu/c4x/StanfordOnline/O.P.E.N./asset/course_design_tile.jpg"
              /> */}
                <div style={{ textAlign: "center" }}>
                  <img
                    src={require("../img/newcourse.svg")}
                    style={{ width: "100px" }}
                  ></img>
                  <h3>Add Course Details</h3>
                  <p>Add Course Cover Pic, Title and Description to proceed</p>
                </div>
                <div
                  style={{
                    backgroundImage: `url(${courseCover})`,
                    backgroundSize: "cover",
                    height: "200px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderStyle: courseCover ? "none" : "dashed",
                    borderWidth: "1px",
                    borderRadius: "8px",
                    marginBottom: "10px",
                  }}
                >
                  <label for="image">
                    <input
                      accept="image/*"
                      // value={coverImage}
                      // onChange={e =>
                      //   setCoverImage(URL.createObjectURL(e.target.files[0]))
                      // }
                      onChange={onCoverImageChange}
                      type="file"
                      name="image"
                      id="image"
                      style={{ display: "none" }}
                    />
                    <Icon
                      type="camera"
                      style={{
                        fontSize: "40px",
                        display: courseCover ? "none" : "block",
                        color: "lightgray",
                      }}
                    />
                    {/* <Avatar
                    style={{
                      backgroundColor: "white",
                      zIndex: "0",
                      color: "black",
                      margin: "20px",
                      opacity: 1,
                      cursor: "pointer",
                      marginTop: "-30px"
                    }}
                    icon="camera"
                    size={80}
                  /> */}
                  </label>
                </div>

                <Input
                  style={{ marginBottom: "10px" }}
                  width="100%"
                  size="large"
                  placeholder="Course Title"
                  value={courseTitle}
                  onChange={(e) => setCourseTitle(e.target.value)}
                />

                <TextArea
                  value={courseDesc}
                  onChange={(e) => setCourseDesc(e.target.value)}
                  rows={4}
                  placeholder="Course Description"
                  style={{ marginBottom: "10px" }}
                ></TextArea>
                <Button
                  loading={loading}
                  type="primary"
                  size="large"
                  block
                  style={{ background: "#B3315B", borderColor: "#B3315B" }}
                  onClick={(e) => {
                    if (
                      courseTitle !== "" &&
                      courseDesc !== "" &&
                      courseCover !== null
                    ) {
                      setStep1(true);
                    } else {
                      message.destroy();
                      message.error("Please fill all fields");
                    }
                  }}
                >
                  Next
                </Button>
              </div>
            </div>
          ) : (
            <div>
              <Row>
                <Col
                  //   className="shadow"
                  span={10}
                  style={{
                    // borderRight: "1px dashed lightgray",
                    height: "100vh",
                  }}
                >
                  <div style={{ padding: "20px" }}>
                    <Input
                      size="large"
                      value={query}
                      onChange={(e) => setQuery(e.target.value)}
                      placeholder="search with video name or folder name..."
                      suffix={
                        <Icon type="search" style={{ paddingTop: "9px" }} />
                      }
                    />
                  </div>
                  <Divider style={{ marginTop: "4px", marginBottom: "0px" }} />
                  <div style={{ padding: "0px 20px" }}>
                    {filteredFiles.map((item, index) => {
                      if (item.file_type === "video") {
                        return (
                          <>
                            <Card
                              hoverable
                              title={
                                <>
                                  <img
                                    //   style={{ marginTop: "-5px" }}
                                    src={require(
                                      `../img/file-types/${item.file_type}.png`
                                    )}
                                    alt=""
                                    width="20px"
                                  />
                                  &nbsp;&nbsp;{item.name}
                                </>
                              }
                              extra={showIcon(item)}
                              onClick={(e) => addtoCourse(item)}
                              bodyStyle={{
                                padding: "10px",
                                backgroundColor: "#F5F5F5",
                              }}
                              //   headStyle={{ padding: "5px 8px" }}
                              style={{
                                margin: "20px 0px",
                                borderRadius: "8px",
                                cursor: "pointer",
                              }}
                            >
                              <Meta
                                description={
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Icon
                                      type="home"
                                      style={{ fontSize: "12px" }}
                                    />
                                    &nbsp;&nbsp;{item.path}
                                  </div>
                                }
                              />
                            </Card>
                          </>
                        );
                      }
                    })}
                  </div>
                </Col>
                <Col
                  span={14}
                  style={{
                    // padding: "10px",
                    // backgroundColor: "#1D87D0",s
                    backgroundImage: `URL(${blurbg})`,
                    backgroundSize: "cover",
                    // opacity: "0.5",
                    // borderRadius: "0px 20px 20px 0px",
                    color: "white",
                    height: "100vh",
                  }}
                >
                  {/* <h2>{courseTitle}</h2>
                  <p>{courseDesc}</p>
                  <Divider style={{ margin: "5px 0px" }} /> */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "20px",
                    }}
                  >
                    <h3
                      style={{
                        color: "white",
                        textAlign: "center",
                        margin: "0px",
                      }}
                    >
                      Organize your Course
                    </h3>
                    <Button
                      shape="round"
                      size="large"
                      onClick={(e) => {
                        setFinalArr({
                          name: courseTitle,
                          description: courseDesc,
                          cover: courseCoverS3url,
                          capters: courseArr,
                        });
                        // "https://pulseapi.globalxchange.io/new_course"
                        message.loading("Publishing the course...", 0);
                        axios
                          .post(
                            pulseTradingFloorApiUrl + "new_course",
                            {
                              name: courseTitle,
                              description: courseDesc,
                              cover_url: courseCoverS3url,
                              chapters: courseArr,
                              origination_source: "gx_brain",
                            },
                            {
                              headers: {
                                token: localStorage.getItem("token"),
                                email: localStorage.getItem("user_account"),
                              },
                            }
                          )
                          .then((res) => {
                            if (res.data.status) {
                              message.destroy();
                              message.success("Publish Success", 3);
                              window.location.reload();
                            } else {
                              message.destroy();
                              res.data.payload.length > 30
                                ? message.warning(res.data.payload, 15)
                                : message.error("Publish failed");
                            }
                          });
                      }}
                    >
                      PUBLISH <Icon type="right-circle" />
                    </Button>
                  </div>
                  <Divider style={{ marginTop: "4px" }} />
                  {courseArr.length > 0
                    ? courseArr.map((item, index) => {
                        return (
                          <>
                            <Card
                              hoverable
                              title={
                                <>
                                  {/* <img
                                    //   style={{ marginTop: "-5px" }}
                                    src={require(`../img/file-types/${item.file_type}.png`)}
                                    alt=""
                                    width="20px"
                                  /> */}
                                  <Icon
                                    type="menu"
                                    style={{ color: "lightgray" }}
                                  />
                                  &nbsp;&nbsp;{item.name}
                                </>
                              }
                              extra={
                                <Icon
                                  type="edit"
                                  onClick={(e) => {
                                    setSelectedChapter({ ...item, index });
                                    setVisibleEditChapter(true);
                                  }}
                                />
                              }
                              bodyStyle={{
                                padding: "0px",
                                backgroundColor: "#F5F5F5",
                              }}
                              style={{
                                margin: "15px",
                                borderRadius: "8px",
                                cursor: "pointer",
                              }}
                            ></Card>
                          </>
                        );
                      })
                    : ""}
                  <div
                    className="bottom-info"
                    style={{ marginBottom: "50px", textAlign: "center" }}
                  ></div>
                </Col>
              </Row>
            </div>
          )}
        </Col>
      </Row>

      {/* Edit Chapter Modal */}

      <Modal
        destroyOnClose
        title="Edit Chapter Info"
        visible={visibleEditChapter}
        onCancel={(e) => setVisibleEditChapter(false)}
        footer={null}
      >
        <Input
          placeholder={
            selectedChapter.title ? selectedChapter.title : "Chapter Title"
          }
          style={{ marginBottom: "10px" }}
          //   ref={chapterTitleRef}
          value={chapterTitle}
          onChange={(e) => setChapterTitle(e.target.value)}
        />

        <TextArea
          style={{ marginBottom: "10px" }}
          rows={4}
          placeholder={
            selectedChapter.description
              ? selectedChapter.description
              : "Chapter Description"
          }
          //   ref={chapterDescRef}
          value={chapterDesc}
          onChange={(e) => setChapterDesc(e.target.value)}
        ></TextArea>
        <div
          style={{
            backgroundImage: `url(${thumbnail})`,
            backgroundSize: "cover",
            height: "200px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderStyle: thumbnail ? "none" : "dashed",
            borderWidth: "1px",
            borderRadius: "8px",
            marginBottom: "10px",
          }}
        >
          <label for="image">
            <input
              accept="image/*"
              // value={coverImage}
              // onChange={e =>
              //   setCoverImage(URL.createObjectURL(e.target.files[0]))
              // }
              onChange={onChapterImageChange}
              type="file"
              name="image"
              id="image"
              style={{ display: "none" }}
            />
            <Icon
              type="camera"
              style={{
                fontSize: "40px",
                display: thumbnail ? "none" : "block",
                color: "lightgray",
              }}
            />
          </label>
        </div>
        <Button
          loading={loading}
          block
          type="primary"
          size="large"
          onClick={(e) => {
            // console.log(chapterTitle, chapterDesc);
            updateChapter(chapterTitle, chapterDesc);
          }}
        >
          SUBMIT
        </Button>
      </Modal>
    </>
  );
};

export default CreateCourse;
