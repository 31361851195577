import React, { useContext, useState } from "react";
import { Row, Col, Input, Button, Carousel, message } from "antd";
import Axios from "axios";
import { FileContext } from "../Context";

const SplashPage = () => {
  const value = useContext(FileContext);
  const gxBrainRegister = () => {
    message.loading("Registering...", 3);
    Axios.post(`${value.url}account/register-user`, {
      email: value.email,
      token: value.token,
    }).then((res) => {
      if (res.data.status) {
        message.destroy();
        message.success("Registration Success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        message.destroy();
        message.error("Registration failed", 3);
      }
    });
  };

  return (
    <>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: window.innerHeight,
            padding: "50px",
          }}
        >
          <div style={{ fontSize: "20px", padding: "40px" }}>
            <h4>Hello {value.username.length > 0 ? value.username : ""}</h4>
            <h1 style={{ fontWeight: "bold" }}>Welcome to MyCabinet</h1>
            <p style={{ fontSize: "17px" }}>
              We are building a new protocol for the exchange of files. Some
              might even call it a <b>"FileXChange"..</b> Get it????
            </p>
            <p style={{ paddingTop: "0px", fontSize: "17px" }}>
              You are one of the first users to try it out so please be kind to
              the app. You are given 1 GB worth of secure storage for free.
              Please ask your GXSupport team for more information.
            </p>

            <Button type="primary" size="large" block onClick={gxBrainRegister}>
              ENTER
            </Button>
          </div>
        </Col>
        <Col
          span={12}
          style={{
            // paddingTop: window.innerHeight / 2 - 270,
            // backgroundColor: "#40A9FF",
            textAlign: "center",
            height: window.innerHeight,
            alignItems: "center",
          }}
        >
          <img
            src={require("../img/doc_wall.jpg")}
            alt=""
            // width="100%"
            // height="100%"
          />

          {/* <div class="carousel slide" data-ride="carousel">
            <div class="carousel-item">
              <img
                src={require("../img/storage.svg")}
                alt=""
                // width="100%"
                // height="100%"
              />
              <div class="carousel-caption d-none d-md-block">
                <h5 style={{ fontWeight: "bold", fontSize: "20px" }}>
                  10 GB Storage, for all your documents.
                </h5>
              </div>
            </div>
            <div class="carousel-item">
              <img
                src={require("../img/secure-file-share.svg")}
                alt=""
                // width="100%"
                // height="350px"
              />
              <div class="carousel-caption d-none d-md-block">
                <h5 style={{ fontWeight: "bold", fontSize: "20px" }}>
                  Share your files securly within Nitrous <br />
                  and also with your friends.
                </h5>
              </div>
            </div>
            <div class="carousel-item">
              <img
                src={require("../img/bookmark.svg")}
                alt=""
                // width="100%"
                // height="350px"
              />
              <div class="carousel-caption d-none d-md-block">
                <h5 style={{ fontWeight: "bold", fontSize: "20px" }}>
                  Save eveything, Bookmarks too!
                </h5>
              </div>
            </div>
            <div class="carousel-item">
              <img
                src={require("../img/storage.svg")}
                alt=""
                // width="100%"
                // height="100%"
              />
              <div class="carousel-caption d-none d-md-block">
                <h5 style={{ fontWeight: "bold", fontSize: "20px" }}>
                  Supports unlimited downloads of your files & folders
                </h5>
              </div>
            </div>
            {/* <Carousel autoplay dots="false">
              <div>
                <img
                  src={require("../img/storage.svg")}
                  alt=""
                  // width="100%"
                  // height="100%"
                />
                <br />
                <h3 style={{ fontWeight: "bold", fontSize: "20px" }}>
                  10 GB Storage, for all your documents.
                </h3>
              </div>
              <div>
                <img
                  src={require("../img/secure-file-share.svg")}
                  alt=""
                  // width="100%"
                  // height="350px"
                />
                <br />
                <h3 style={{ fontWeight: "bold", fontSize: "20px" }}>
                  Share your files securly within Nitrous <br />
                  and also with your friends.
                </h3>
              </div>
              <div>
                <img
                  src={require("../img/bookmark.svg")}
                  alt=""
                  // width="100%"
                  // height="350px"
                />
                <br />
                <h3 style={{ fontWeight: "bold", fontSize: "20px" }}>
                  Save eveything, Bookmarks too!
                </h3>
              </div>
              <div>
                <img
                  src={require("../img/download.svg")}
                  alt=""
                  // width="100%"
                  // height="350px"
                />
                <br />
                <h3 style={{ fontWeight: "bold", fontSize: "20px" }}>
                  Supports unlimited downloads of your files & folders
                </h3>
              </div>
            </Carousel>
          </div>
        */}
        </Col>
      </Row>
    </>
  );
};

export default SplashPage;
