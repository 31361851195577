import React, { useContext, useState, useEffect } from "react";
import {
  Row,
  Col,
  Divider,
  Form,
  Icon,
  Input,
  Button,
  Card,
  Badge,
  Result,
  Avatar,
  Modal,
  message,
  Typography,
  Tooltip,
} from "antd";

import { Link } from "react-router-dom";
import { FileContext } from "../Context";
import Sidebar from "./Sidebar";
import NavMobile from "./NavMobile";
import axios from "axios";

const { Meta } = Card;
const { Paragraph } = Typography;
const { Search } = Input;
const { confirm } = Modal;

const SubDomain = () => {
  const value = useContext(FileContext);
  const [subdomain, setSubdomain] = useState("");
  const [loading, setLoading] = useState(false);
  const [userSharedFiles, setUserSharedFiles] = useState([]);
  //   const [confirm, setConfirm] = useState(false);
  const [mySubdomain, setMySubdomain] = useState("");

  const showConfirm = () => {
    confirm({
      title: "Congrats! Subdomain is available.",
      content: "Please click OK to select the subdomain, or CANCEL",
      onOk() {
        if (mySubdomain === "") {
          confirmSubdomain();
        } else {
          changeSubdomain();
        }
      },
      onCancel() {},
    });
  };

  const confirmSubdomain = () => {
    axios
      .post(value.url + `file/register-subdomain`, {
        email: localStorage.getItem("user_account"),
        subdomain: subdomain,
        token: localStorage.getItem("token"),
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.status) {
          message.success("subdomain created successfully");
        }
      });
  };

  const changeSubdomain = () => {
    axios
      .post(value.url + `file/update-subdomain`, {
        email: localStorage.getItem("user_account"),
        subdomain: subdomain,
        token: localStorage.getItem("token"),
      })
      .then((res) => {
        if (res.data.status) {
          message.success("subdomain changed successfully");
        }
      });
  };

  const checkSubdomainAvilability = () => {
    // Endpoint: /file/check-subdomain /file/register-subdomain
    // Body: { "email":"fasfa@ga,com", subdomain:"avvbds"}
    axios
      .post(value.url + `file/check-subdomain`, {
        email: localStorage.getItem("user_account"),
        subdomain: subdomain,
        token: localStorage.getItem("token"),
      })
      .then((res) => {
        if (res.data.status === true) {
          showConfirm();
        } else {
          message.error(
            "Subdomain not available, please choose something else"
          );
        }
      });
  };

  useEffect(() => {
    console.log("all shared", value.url);
    axios
      .post(value.url + "file/user-shared-files", {
        //username: "karthik",
        email: localStorage.getItem("user_account"),
        token: localStorage.getItem("token"),
      })
      .then((res) => {
        if (res.data.status) {
          console.log("all shared", res.data.payload);
          setUserSharedFiles([...res.data.payload]);
        }
      });
    axios
      .post(value.url + "file/get-my-subdomain", {
        email: localStorage.getItem("user_account"),
        token: localStorage.getItem("token"),
      })
      .then((res) => {
        if (res.data.status) {
          setMySubdomain(res.data.payload.subdomain);
        } else {
          setMySubdomain("");
        }
      });
  }, []);

  const removeShareAccessForAll = (item) => {
    console.log(value.path + item.name);
    message.loading("Deleting File..", 0);
    axios
      .post(value.url + "file/remove_all_share_access", {
        //username: "karthik",
        email: value.email,
        token: value.token,
        name: item.name,
        path: value.path + item.name,
        just_path: value.path,
        folder: false,
      })
      .then((res) => {
        console.log(res.data.payload);
        let tempObj = [...userSharedFiles];
        console.log(tempObj);
        if (res.data.status) {
          tempObj.splice(
            tempObj.findIndex(
              (subitem) =>
                subitem.name === item.name &&
                subitem.path === item.path &&
                subitem.folder === item.folder
            ),
            1
          );
          console.log(tempObj);
          setUserSharedFiles([...tempObj]);
          message.destroy();
          message.success("Revoke All Access Success", 3);
        } else {
          message.destroy();
          message.error("Revoke All Access Failed", 3);
        }
      });
  };

  return (
    <>
      <Row style={{ minHeight: "100vh", position: "relative" }}>
        {window.innerWidth > 600 ? (
          <Col
            //   className="shadow"
            //   height="100vh"
            xs={24}
            sm={4}
            style={{
              backgroundColor: "white",
              position: "fixed",
              left: 0,
              top: 0,
              height: "100vh",
            }}
          >
            <Sidebar />
          </Col>
        ) : (
          <NavMobile />
        )}

        <Col
          xs={24}
          sm={20}
          offset={window.innerWidth > 600 ? 4 : 0}
          style={{
            backgroundColor: "#FFFFFF",
            height: window.innerHeight,
          }}
        >
          <div>
            <div
              className="shadow"
              style={{
                padding: "15px",
                fontSize: "20px",
                color: "#B3315B",
                fontWeight: "bold",
                display: window.innerWidth > 600 ? "flex" : "block",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>
                <Icon type="link" /> Shared Files and Subdomain
              </div>
              <Search
                // disabled={mySubdomain !== ""}
                id="link"
                size="large"
                value={subdomain}
                onChange={(e) =>
                  setSubdomain(
                    e.target.value.toLowerCase().replace(/[^A-Z0-9]+/gi, "")
                  )
                }
                onSearch={checkSubdomainAvilability}
                placeholder="Set your subdomain."
                loading={loading}
                enterButton={"Check Availability"}
                style={{
                  margin: "10px 0px",
                  width: window.innerWidth > 600 ? "40vw" : "auto",
                }}
              />
            </div>
            <Divider style={{ margin: "0px" }} />
          </div>
          <div>
            <div
              style={{
                padding: "30px",
                display: window.innerWidth > 600 ? "flex" : "block",
                justifyContent: "space-between",
              }}
            >
              <div>
                <h2>Your Subdomain:</h2>
                <p>
                  <code
                    style={{
                      fontWeight: "bold",
                      color: "#B3315B",
                      fontSize: window.innerWidth > 600 ? "25px" : "18px",
                    }}
                  >
                    {mySubdomain !== ""
                      ? mySubdomain + ".brain.stream"
                      : "Set a Subdomain to share files"}
                  </code>{" "}
                </p>
              </div>
              <div>
                <h2>Instructions:</h2>
                <p>
                  if your name is <b>John</b> and you want your shared links to
                  look like the following
                  <br />
                  <code style={{ fontWeight: "bold", color: "#B3315B" }}>
                    john.brain.stream/#/abcxyz
                  </code>{" "}
                  <br />
                  then just write <b>John</b> in that input box and check if the
                  name is available
                </p>
              </div>
            </div>
            <Divider style={{ margin: "0px" }} />
          </div>
          <div style={{ padding: "30px" }}>
            <div style={{ height: "50vh" }} className="scroll-style">
              {userSharedFiles.length > 0 ? (
                <>
                  {userSharedFiles.map((item) => {
                    return (
                      <Card
                        // style={{ borderRadius: "8px", margin: "10px 0px" }}
                        // bodyStyle={{ padding: "18px" }}
                        bodyStyle={{ padding: "20px" }}
                        className="shadow-sm"
                        style={{
                          borderRadius: "8px",
                          border: "none",
                          marginTop: "8px",
                          marginBottom: "10px",
                        }}
                      >
                        <Row
                          style={{
                            display: "flex",
                            flexDirection:
                              window.innerWidth > 600 ? "row" : "column",
                          }}
                        >
                          <Col
                            xs={24}
                            sm={16}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              fontSize: "15px",
                              fontWeight: "600",
                              // marginTop: "-9px"
                            }}
                          >
                            <img
                              src={require(
                                `../img/file-types/${item.file_type}.png`
                              )}
                              width="25px"
                              alt=""
                            />
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <span>{item.name}</span>
                          </Col>

                          <Col
                            xs={24}
                            sm={8}
                            style={{ textAlign: "right", marginTop: "4px" }}
                          >
                            <Row
                              gutter={16}
                              style={{
                                marginTop:
                                  window.innerWidth > 600 ? "0x" : "15px",
                              }}
                            >
                              <Col
                                xs={4}
                                sm={9}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  fontSize: "15.5px",
                                  fontWeight: "500",
                                  // justifyContent: "center"
                                }}
                              >
                                <i
                                  class="fa fa-database"
                                  style={{ color: "#757575" }}
                                ></i>
                                &nbsp;&nbsp;
                                {(item.file_size / 10 ** 6).toFixed(3) >
                                1000 ? (
                                  <>
                                    {" "}
                                    {(item.file_size / 10 ** 9).toFixed(3)}
                                    &nbsp;GB
                                  </>
                                ) : (item.file_size / 10 ** 6).toFixed(3) >
                                  0.1 ? (
                                  <>
                                    {(item.file_size / 10 ** 6).toFixed(3)}
                                    &nbsp;MB
                                  </>
                                ) : (
                                  <>
                                    {(item.file_size / 10 ** 3).toFixed(3)}
                                    &nbsp;KB
                                  </>
                                )}
                              </Col>
                              <Col
                                xs={4}
                                sm={15}
                                style={{
                                  fontSize: "15.5px",
                                  fontWeight: "500",
                                  // justifyContent: "center"
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    color: "#E94848",
                                    cursor: "pointer",
                                  }}
                                  onClick={(e) => removeShareAccessForAll(item)}
                                >
                                  <Icon type="link" />
                                  &nbsp;
                                  <div>Revoke Sharing</div>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Card>
                    );
                  })}
                </>
              ) : (
                <Result status="404" title="No Shared File" />
              )}
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default SubDomain;
