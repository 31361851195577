import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Row,
  Col,
  Input,
  Icon,
  Card,
  Divider,
  Alert,
  Menu,
  Dropdown,
  Modal,
  Button,
  message,
  Popover,
  Tooltip,
  Progress,
  Empty,
  Typography,
  Tabs,
  Select,
  Drawer,
} from "antd";

import { FileContext } from "../Context";
import InFolder from "./InFolder";
import FileDownload from "js-file-download";
import Loader from "react-loader-spinner";

import Viewer from "react-viewer";
import ReactPlayer from "react-player";
import PDFViewer from "mgr-pdf-viewer-react";

import { sign } from "jsonwebtoken";

import axios from "axios";
import { useDropzone } from "react-dropzone";

const { TabPane } = Tabs;
const { Paragraph } = Typography;
const { Option } = Select;
const { Search } = Input;

const ContentArea = () => {
  const value = useContext(FileContext);
  const history = useHistory();
  const emailRegex = new RegExp(/.*@.*\..*/);
  var tempFiles = [];
  // var multiImageLink = [];
  const [dup_accepted_files, set_dup_accepted_files] = useState([]);
  const [quickImg, setQuickImg] = useState(false);
  // const [files, setFiles] = useState();
  const { acceptedFiles, getRootProps, getInputProps, inputRef } = useDropzone({
    onDrop: (acceptedFiles) => {
      set_dup_accepted_files([...acceptedFiles]);
    },
    noKeyboard: true,
    accept:
      "video/avi, video/msvideo, video/x-msvideo, video/mp4, video/x-flv, video/quicktime, image/bmp, text/css, text/csv, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/epub+zip, application/gzip, image/gif, text/html, image/vnd.microsoft.icon, image/jpeg, text/javascript, application/json, audio/mpeg, image/png, application/pdf, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.rar, image/svg+xml, application/x-tar, image/tiff, text/plain, audio/wav, application/xhtml+xml, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/zip, application/x-7z-compressed, application/vnd.ms-excel,	application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .apk, .zip",
  });

  const [rootFolderList, setRootFolderList] = useState([]);

  const [visibleCreateFolder, setVisibleCreateFolder] = useState(false);
  const [visibleRenameFolder, setVisibleRenameFolder] = useState(false);
  const [newFolderName, setNewFolderName] = useState("");
  const [renameFolderName, setRenameFolderName] = useState("");
  const [currentFocusFolder, setCurrentFocusFolder] = useState({});
  const [query, setQuery] = useState("");
  const [visibleUpload, setVisibleUpload] = useState(false);
  const [removeSecret, setremoveSecret] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [downloadProgressVisible, setDownloadProgressVisible] = useState(false);
  const [totalFileSize, setTotalFileSize] = useState(0);
  const [downloadingFile, setDownloadingFile] = useState("");
  const [downloadFinished, setDownloadFinished] = useState(false);
  const [zipDownload, setZipDownload] = useState(false);

  const [uploadStarted, setUploadStarted] = useState(false);
  const [uploadFinished, setUploadFinished] = useState(false);

  const [visibleShareFile, setVisibleShareFile] = useState(false);
  const [currentFile, setCurrentFile] = useState({});
  const [response, setResponse] = useState({});
  const [currentFileAccessList, setcurrentFileAccessList] = useState([]);
  const [fileRecipient, setFileRecipient] = useState("");
  const [currentShareableLink, setCurrentShareableLink] = useState("");

  const [shareableGxLink, setShareableGxLink] = useState("");
  const [shareablePublicLink, setShareablePublicLink] = useState("");

  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [deleteFileConfirmation, setDeleteFileConfirmation] = useState(false);
  const [fileTobeDeleted, setFileTobeDeleted] = useState({});

  const [videoUrl, setVideoUrl] = useState("");
  const [audioUrl, setAudioUrl] = useState("");
  const [pdfUrl, setPdfUrl] = useState("");
  const [pdfEnable, setPdfEnable] = useState(false);
  const [videoEnable, setVideoEnable] = useState(false);
  const [audioEnable, setAudioEnable] = useState(false);
  const [imageEnable, setImageEnable] = useState(false);
  const [imageList, setImageList] = useState([]);
  const [zoomScale, setZoomScale] = useState(1.0);
  const [loading, setLoading] = useState(false);
  const [customLink, setCustomLink] = useState("");
  const [filePassword, setFilePassword] = useState("");
  const [fileExpiry, setFileExpiry] = useState(0);
  const [editClicked, setEditClicked] = useState(false);
  const [publicLinkObj, setPublicLinkObj] = useState({});

  const [selectedItem, setSelectedItem] = useState(null);
  const [visibleQuickShareFile, setVisibleQuickShareFile] = useState(false);
  const [quickItem, setQuickItem] = useState(null);

  const [multipleImages, setMultipleImages] = useState([]);
  const [visibleMultiShareFile, setVisibleMultiShareFile] = useState(false);
  const [multiImageLink, setMultiImageLink] = useState([]);
  const [quick, setQuick] = useState(false);
  var tempppp = [];
  // useEffect(() => {
  //   if (editClicked === true) {
  //     setFilePassword(publicLinkObj.secret);
  //   }
  // }, [editClicked]);

  const checkCustomLinkAvilability = () => {
    setLoading(true);
    axios
      .post(value.url + `gxshare/check-link-availability`, {
        custom_link: customLink,
        email: localStorage.getItem("user_account"),
        token: localStorage.getItem("token"),
      })
      .then((res) => {
        if (res.data.status === true) {
          setLoading(false);
          message.destroy();
          message.success(res.data.payload);
        } else {
          setLoading(false);
          message.destroy();
          message.error(res.data.payload);
        }
      });
  };

  let filteredFiles = value.fileFolderData.filter((item) => {
    const lowquery = query?.toLowerCase();
    return item.name?.toLowerCase().indexOf(lowquery) >= 0 && !item.folder;
  });

  let filteredEmails = value.gxUserEmails.filter((item) => {
    const lowquery = fileRecipient?.toLowerCase();
    return item?.toLowerCase().indexOf(lowquery) >= 0;
  });

  const emailsPopover = (
    <div style={{ padding: "8px" }}>
      {fileRecipient.length > 0 ? (
        filteredEmails.length > 0 ? (
          filteredEmails.map((item, index) => {
            if (index < 15) {
              return (
                <h6
                  style={{ cursor: "pointer" }}
                  onClick={(e) => setFileRecipient(item)}
                >
                  {item}
                </h6>
              );
            }
          })
        ) : (
          <h4> ☹️ Email not found </h4>
        )
      ) : (
        <h4>Type email of recipient</h4>
      )}
    </div>
  );

  useEffect(() => {
    var linkobj = publicLinkObj;
    // console.log(value.url);
  }, [publicLinkObj]);

  const removeFile = (file) => () => {
    console.log("removeFile...");
    acceptedFiles.splice(acceptedFiles.indexOf(file), 1);
    console.log(acceptedFiles);
  };

  const removeAll = () => {
    console.log("removeAll...");
    acceptedFiles.length = 0;
    acceptedFiles.splice(0, acceptedFiles.length);
    if (inputRef.current != null) {
      inputRef.current.value = "";
    } else {
    }
    console.log(acceptedFiles);
  };

  const editFileName = (str, index) => {
    files[index].path = str;
    console.log("changed string", str, index);
  };

  const files = acceptedFiles.map((file, index) => (
    <p key={file.path} style={{ marginBottom: "5px" }}>
      {/* <Paragraph
      style={{ fontWeight: "bold" }}
      editable={{ onChange: (value) => editFileName(value, index) }}
    >
      {file.path}
    </Paragraph> */}
      <span style={{ fontWeight: "bold" }}>{file.name}</span>
      &nbsp;- &nbsp;
      {(file.size / 10 ** 6).toFixed(3)} MB &nbsp;&nbsp;
      {/* <Icon
      type="edit"
      // style={{ color: "red" }}
      onClick={editFileName(file)}
    ></Icon> */}
    </p>
  ));

  // const checkFilename = async () => {
  //   let reader = new FileReader();
  //   reader.onload = function (event) {
  //     console.log(event.target.result, "allfiles"); // data url!
  //     // setAllFiles(event.target.result);
  //     // document.getElementById("pastedImage").src = event.target.result;
  //   };

  //   // const formData = new FormData();

  //   // await Promise.all(
  //   //   acceptedFiles.map((item) => formData.append("files", item))
  //   // );

  //   // console.log(acceptedFiles, "filessss");

  //   axios
  //     .post("https://drivetest.globalxchange.io/" + "folder/list-contents", {
  //       email: localStorage.getItem("user_account"),
  //       token: localStorage.getItem("token"),
  //     })
  //     .then((res) => {
  //       let all_data = [...res.data];

  //       // console.log(
  //       //   files[0].key.replace(/[^0-9a-zA-Z-_]/g, ""),
  //       //   all_data[0].name.replace(/[^0-9a-zA-Z-_]/g, ""),
  //       //   "allfiles"
  //       // );
  //       // if (
  //       //   files[0].key.replace(/[^0-9a-zA-Z-_]/g, "") ===
  //       //   all_data[0].name.replace(/[^0-9a-zA-Z-_]/g, "")
  //       // ) {
  //       //   message.error("File with same name already exits");
  //       // } else {
  //       //   message.success("Now Upload");
  //       //   // fileUploadHandler();
  //       // }

  //       // let output;
  //       // formData.map((item, index) => {
  //       //   all_data.map((item1, index1) => {
  //       //     console.log(
  //       //       item.key.replace(/[^0-9a-zA-Z-_]/g, ""),
  //       //       item1.name.replace(/[^0-9a-zA-Z-_]/g, ""),
  //       //       "allfiles"
  //       //     );
  //       //     if (
  //       //       item.key.replace(/[^0-9a-zA-Z-_]/g, "") ===
  //       //       item1.name.replace(/[^0-9a-zA-Z-_]/g, "")
  //       //     ) {
  //       //       item.key = "1" + item.key;
  //       //     }
  //       //   });
  //       // });
  //     });
  // };

  useEffect(() => {
    console.log("next_chunk_content useeffect");
    value.socket.on("next_chunk_content", async (data) => {
      console.log(data, "lkwflwhlefkhwlef");

      let already_uploaded_data = data.uploaded_bytes;
      let required_file = acceptedFiles.find(
        (find_file) => find_file.name === data.file_name
      );
      // console.log(required_file);

      let slice_end =
        already_uploaded_data + value.chunk_size > required_file.size
          ? required_file.size
          : already_uploaded_data + value.chunk_size;
      let file = required_file.slice(already_uploaded_data, slice_end);
      // console.log(slice_end);
      // console.log(file);

      const secret = "uyrw7826^&(896GYUFWE&*#GBjkbuaf";

      const token = sign(
        {
          file_name: required_file.name,
          file_size: required_file.size,
          email: value.email,
        },
        secret,
        {
          algorithm: "HS512",
          expiresIn: 240,
          issuer: "gxjwtenchs512",
        }
      );
      let src = "content";
      let resp = await axios.post(
        value.url +
          `file/large_file_upload?username=karthik&email=${value.email}&path=${value.path}&token=${token}&file_name=${required_file.name}&src=${src}`,
        file,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "total-file-size": required_file.size,
          },
        }
      );
      // console.log(resp);

      if (resp.data.status === false) {
        message.error("File Size Exceeds Your Free Quota", 4);
        setUploadStarted(false);
        setUploadFinished(false);
        setVisibleUpload(false);
        removeAll();
        setTimeout(() => {
          window.location.reload();
        }, 4000);
      }

      if (resp.data.payload.message === "Completely Uploaded") {
        console.log("cnt", "   completed", required_file.name);
        if (acceptedFiles.length === 1) {
          console.log("balllooooooo");
          setUploadFinished(true);
          message.success("File Upload Success", 3);
          setTimeout(() => {
            setUploadStarted(false);
            setUploadFinished(false);
            setVisibleUpload(false);
            removeAll();
            window.location.reload();
          }, 2000);
        } else {
          let temp_accepted_files = [...dup_accepted_files];
          let uploaded_count = 1;
          for (const temp_file of temp_accepted_files) {
            if (temp_file.name === required_file.name) {
              console.log("cnt", "adding true", "     ", temp_file.name);
              temp_file["finished"] = true;
            } else {
              if (temp_file.finished) uploaded_count++;
            }
          }
          set_dup_accepted_files([...temp_accepted_files]);
          console.log("cnt", dup_accepted_files);
          console.log("cnt", uploaded_count);
          if (uploaded_count === acceptedFiles.length) {
            setUploadFinished(true);
            message.success("File Upload Success", 3);
            setTimeout(() => {
              setUploadStarted(false);
              setUploadFinished(false);
              setVisibleUpload(false);
              removeAll();
              window.location.reload();
            }, 2000);
          }
        }
      }
    });
    return () => {
      console.log("offing content");
      value.socket.off("next_chunk_content");
    };
  }, [acceptedFiles, dup_accepted_files]);

  useEffect(() => {
    var tempSelectedData = [];
    if (localStorage.getItem("quickImage")) {
      let files = JSON.parse(localStorage.getItem("quickImage"));
      console.log(
        localStorage.getItem("quickImage"),
        files,
        "uuuuuuuuuuuuuuuuuuuqqq"
      );
      files.map((item) => {
        rootFolderList.map((item1) => {
          if (item1.name === item) {
            tempSelectedData.push(item1);
            // setCurrentFile(item);
            // setSelectedItem(item);
          }
        });
      });
      setMultipleImages([...tempSelectedData]);
    }
  }, [rootFolderList]);

  useEffect(() => {
    if (multipleImages.length > 0) {
      multipleImages.map((item) => {
        shareMultiFileHandlerPublic(item);
      });
      console.log(tempppp, "jkqdkjqdgiuqdg");
      // setMultiImageLink([...tempppp]);
      setVisibleMultiShareFile(true);
    }
  }, [multipleImages]);

  const shareMultiFileHandlerPublic = (item) => {
    // message.destroy();
    // message.loading("Encrypting and creating link", 0);
    axios
      .post(value.url + "file/share-file", {
        //username: "karthik",
        email: value.email,
        token: value.token,
        path: item.path,
        name: item.name,
        folder: item.folder,
        custom_link: null,
        secret: null,
        end_time: 0,
      })
      .then((res) => {
        if (res.data.status) {
          // message.destroy();
          // message.success("Public Link Generated Successfully");
          // setShareablePublicLink(res.data.payload);
          // setVisibleShareFile(false);
          tempppp.push(res.data.payload);
          console.log(tempppp, "jkqdkjqdgiuqdg");
          setMultiImageLink([...tempppp]);
        }
      });
  };

  useEffect(() => {
    console.log(multiImageLink, "jkqdkjqdgiuqdg");
  }, [multiImageLink]);

  useEffect(() => {
    console.log(currentFile, "uuuuuuuuuuuuuuuuuuuqqq");
    if (currentFile.name) {
      console.log(currentFile, "uuuuuuuuuuuuuuuuuuu");
      shareFileHandlerPublic();
      setVisibleShareFile(true);
    }
  }, [currentFile]);

  const get_file_status = async () => {
    console.log("inside get accept files");
    // let l = [...acceptedFiles];
    // console.log(l);
    // set_dup_accepted_files([...l]);
    let chosen_file_name_set = new Set();

    let totalUploadingFilesSize = 0;

    for (let item of acceptedFiles) {
      totalUploadingFilesSize += item.size;
      let modified_filename = item.name.replace(/[^0-9a-zA-Z-_.]/g, "");
      tempFiles.push(modified_filename);
      // console.log(item);
      chosen_file_name_set.add(modified_filename + item.size.toString());
    }
    console.log(tempFiles, "hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh");
    if (quick) {
      localStorage.setItem("quickImage", JSON.stringify(tempFiles)); // make it conditional
    }

    let existing_file_name_set = new Set();
    for (let tmp_file of value.fileFolderData) {
      if (tmp_file.path === value.path && !tmp_file.folder) {
        console.log(tmp_file);
        let modified_filename = tmp_file.name.replace(/[^0-9a-zA-Z-_.]/g, "");
        existing_file_name_set.add(
          modified_filename + tmp_file.file_size.toString()
        );
      }
    }

    let intersection = new Set(
      [...existing_file_name_set].filter((x) => chosen_file_name_set.has(x))
    );
    console.log(intersection);
    if (intersection.size !== 0) {
      message.error(
        <b>One or More Files With Same Name Exists In Current Path</b>,
        5
      );
      setUploadStarted(false);
      setUploadFinished(false);
      setVisibleUpload(false);
      removeAll();
    } else {
      if (
        totalUploadingFilesSize +
          value.spaceUsedObject.storage_size +
          value.spaceUsedObject.trash_size >
        value.maxStorageLimit
      ) {
        message.error("File Size Exceeded Your Storage Quota", 4);
        setUploadStarted(false);
        setUploadFinished(false);
        setVisibleUpload(false);
        removeAll();
      } else {
        setUploadStarted(true);
        for (let one_file of acceptedFiles) {
          console.log(one_file.name);

          const data = await value.socket.request("file_upload_status", {
            email: value.email,
            path: value.path,
            size: one_file.size,
            file_name: one_file.name,
          });

          if (data.status === "file is present") {
            message.error(
              <b>One or More Files With Same Name Exists In Current Path</b>,
              5
            );
            setUploadStarted(false);
            setUploadFinished(false);
            setVisibleUpload(false);
            removeAll();
            break;
          } else {
            let already_uploaded_data = data.payload;
            // const formData = new FormData();

            let slice_end =
              already_uploaded_data + value.chunk_size > one_file.size
                ? one_file.size
                : already_uploaded_data + value.chunk_size;
            let file = one_file.slice(already_uploaded_data, slice_end);
            // console.log(slice_end);
            // console.log(file);
            let src = "content";

            const secret = "uyrw7826^&(896GYUFWE&*#GBjkbuaf";
            const token = sign(
              {
                file_name: one_file.name,
                file_size: one_file.size,
                email: value.email,
              },
              secret,
              {
                algorithm: "HS512",
                expiresIn: 240,
                issuer: "gxjwtenchs512",
              }
            );

            let resp = await axios.post(
              value.url +
                `file/large_file_upload?username=karthik&email=${value.email}&path=${value.path}&token=${token}&file_name=${one_file.name}&src=${src}`,
              file,
              {
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  "total-file-size": one_file.size,
                },
              }
            );
            // console.log(resp);
            if (resp.data.status === false) {
              message.error("File Size Exceeds Your Free Quota", 4);
              setUploadStarted(false);
              setUploadFinished(false);
              setVisibleUpload(false);
              removeAll();
              setTimeout(() => {
                window.location.reload();
              }, 4000);
            }
            if (resp.data.payload.message === "Completely Uploaded") {
              if (acceptedFiles.length === 1) {
                console.log("balllooooooo");
                setUploadFinished(true);
                message.success("File Upload Success", 3);
                setTimeout(() => {
                  setUploadStarted(false);
                  setUploadFinished(false);
                  setVisibleUpload(false);
                  removeAll();
                  window.location.reload();
                }, 2000);
              } else {
                console.log("cnt", "kkk");
                let temp_accepted_files = [...dup_accepted_files];
                let uploaded_count = 1;
                for (const temp_file of temp_accepted_files) {
                  if (temp_file.name === one_file.name) {
                    temp_file["finished"] = true;
                  } else {
                    if (temp_file.finished) uploaded_count++;
                  }
                }
                console.log("cnt", uploaded_count);
                set_dup_accepted_files([...temp_accepted_files]);

                if (uploaded_count === acceptedFiles.length) {
                  setUploadFinished(true);
                  message.success("File Upload Success", 3);
                  setTimeout(() => {
                    setUploadStarted(false);
                    setUploadFinished(false);
                    setVisibleUpload(false);
                    removeAll();
                    window.location.reload();
                  }, 2000);
                }
              }
            }
          }
        }
      }
    }
    setQuickImg(false);
  };

  const fileUploadHandler = async () => {
    // acceptedFiles[0].size
    let totalUploadingFilesSize = 0;
    await Promise.all(
      acceptedFiles.map((item) => {
        totalUploadingFilesSize += item.size;
      })
    );

    if (
      totalUploadingFilesSize +
        value.spaceUsedObject.storage_size +
        value.spaceUsedObject.trash_size >
      2 * 10 ** 9
    ) {
      message.error("File Size Exceeded Your Storage Quota", 4);
      setUploadStarted(false);
      setUploadFinished(false);
      setVisibleUpload(false);
      removeAll();
    } else {
      const formData = new FormData();

      await Promise.all(
        acceptedFiles.map((item) => formData.append("files", item))
      );
      for (var key of formData.entries()) {
        console.log(key[0] + ", " + key[1]);
      }
      setUploadStarted(true);

      axios
        .post(
          value.url +
            `file/upload-file?username=karthik&email=${value.email}&path=${value.path}&token=${value.token}`,
          formData,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
            },
          }
        )
        .then((res) => {
          // then print response status
          console.log(res);
          if (res.data.status) {
            setUploadFinished(true);
            message.success("File Upload Success", 3);
            setTimeout(() => {
              setUploadStarted(false);
              setUploadFinished(false);
              setVisibleUpload(false);
              removeAll();
              window.location.reload();
            }, 2000);
          } else {
            message.destroy();
            message.error(res.data.payload, 3);
          }
        })
        .catch((e) => {
          message.error("File Size Exceeds Your Free Quota", 3);

          setUploadStarted(false);
          setUploadFinished(false);
          setVisibleUpload(false);
          removeAll();
        });
    }
  };

  const deleteFolderHandler = () => {
    console.log(currentFocusFolder);
    message.loading("Deleting Folder..", 0);
    axios
      .post(value.url + "folder/delete-folder", {
        //username: "karthik",
        email: value.email,
        token: value.token,
        folderName: currentFocusFolder.name,
        path: value.path,
      })
      .then((res) => {
        console.log(res.data.payload);
        let tempObj = [...value.fileFolderData];
        console.log(tempObj);
        if (res.data.status) {
          tempObj.splice(
            tempObj.findIndex(
              (item) =>
                item.name === currentFocusFolder.name &&
                item.path === currentFocusFolder.path &&
                item.folder === currentFocusFolder.folder
            ),
            1
          );
          value.setfileFolderData([...tempObj]);
          message.destroy();
          message.success("Folder Deletion Success", 3);
          setDeleteConfirmation(false);
        } else {
          message.destroy();
          message.error("Folder Deletion Error", 3);
          setDeleteConfirmation(false);
        }
      });
  };

  const deleteFileStopSharing = () => {
    let item = fileTobeDeleted;
    message.loading("Deleting File..", 0);
    axios
      .post(value.url + "file/delete-file", {
        //username: "karthik",
        email: value.email,
        token: value.token,
        name: item.name,
        path: value.path,
      })
      .then((res) => {
        console.log(res.data.payload);
        let tempObj = [...value.fileFolderData];
        console.log(tempObj);
        if (res.data.status) {
          tempObj.splice(
            tempObj.findIndex(
              (subitem) =>
                subitem.name === item.name &&
                subitem.path === item.path &&
                subitem.folder === item.folder
            ),
            1
          );
          console.log(tempObj);
          value.setfileFolderData([...tempObj]);
          message.destroy();
          message.success("File Deletion Success", 3);
          setCurrentFile({});
          setDeleteFileConfirmation(false);

          axios
            .post(value.url + "file/remove_all_share_access", {
              //username: "karthik",
              email: value.email,
              token: value.token,
              name: item.name,
              path: value.path + item.name,
              just_path: value.path,
              folder: false,
            })
            .then((res) => {
              console.log(res.data.payload);
              //let tempObj = [...userSharedFiles];
              let tempObj = [...value.fileFolderData];
              console.log(tempObj);
              if (res.data.status) {
                tempObj.splice(
                  tempObj.findIndex(
                    (subitem) =>
                      subitem.name === item.name &&
                      subitem.path === item.path &&
                      subitem.folder === item.folder
                  ),
                  1
                );

                console.log(tempObj);
                //setUserSharedFiles([...tempObj]);
                value.setfileFolderData([...tempObj]);
                message.destroy();
                message.success("File deleted and Sharing Stopped", 3);
              } else {
                message.destroy();
                message.error("File deletion Failed", 3);
              }
            });
        } else {
          message.destroy();
          message.error("File Deletion Error", 3);
          setCurrentFile({});
          setDeleteFileConfirmation(false);
        }
      });
  };

  const deleteFileHandler = () => {
    let item = fileTobeDeleted;
    message.loading("Deleting File..", 0);
    axios
      .post(value.url + "file/delete-file", {
        //username: "karthik",
        email: value.email,
        token: value.token,
        name: item.name,
        path: value.path,
      })
      .then((res) => {
        console.log(res.data.payload);
        let tempObj = [...value.fileFolderData];
        console.log(tempObj);
        if (res.data.status) {
          tempObj.splice(
            tempObj.findIndex(
              (subitem) =>
                subitem.name === item.name &&
                subitem.path === item.path &&
                subitem.folder === item.folder
            ),
            1
          );
          console.log(tempObj);
          value.setfileFolderData([...tempObj]);
          message.destroy();
          message.success("File Deletion Success", 3);
          setCurrentFile({});
          setDeleteFileConfirmation(false);
        } else {
          message.destroy();
          message.error("File Deletion Error", 3);
          setCurrentFile({});
          setDeleteFileConfirmation(false);
        }
      });
  };

  const renameFolder = () => {
    if (renameFolderName.length > 0 && renameFolderName.length <= 30) {
      message.destroy();
      message.loading("Renaming Folder In Progess", 0);

      let temp = { path: value.path, name: renameFolderName, folder: true };
      console.log(temp);
      let requiredList = value.fileFolderData.filter((item) => {
        return item.path === "root/";
      });

      let x = requiredList.find((o) => o.name == renameFolderName);

      if (x !== undefined) {
        if (
          x.name !== temp.name &&
          x.path !== temp.path &&
          x.folder !== temp.folder
        ) {
          console.log("can be renamed");
          let tempArr = [...value.fileFolderData];
          tempArr.splice(
            tempArr.findIndex(
              (item) =>
                item.name === currentFocusFolder.name &&
                item.path === currentFocusFolder.path &&
                item.folder === currentFocusFolder.folder
            ),
            1
          );

          axios
            .post(value.url + "folder/rename-folder", {
              //username: "karthik",
              email: value.email,
              token: value.token,
              oldfolderName: currentFocusFolder.name,
              newFolderName: renameFolderName,
              path: currentFocusFolder.path,
            })
            .then((res) => {
              console.log(res.data);
              if (res.data.status) {
                message.destroy();
                message.success("Folder Rename Success", 3);

                value.setfileFolderData([...tempArr, temp]);
                setVisibleCreateFolder(false);
                setNewFolderName("");
              } else {
                message.destroy();
                message.error("Folder Rename Failed", 3);
              }
            });
        } else {
          message.destroy();
          message.warning("Folder Name Already Exists", 3);
        }
      } else {
        console.log("can be renameed");
        let tempArr = [...value.fileFolderData];
        tempArr.splice(
          tempArr.findIndex(
            (item) =>
              item.name === currentFocusFolder.name &&
              item.path === currentFocusFolder.path &&
              item.folder === currentFocusFolder.folder
          ),
          1
        );

        axios
          .post(value.url + "folder/rename-folder", {
            //username: "karthik",
            email: value.email,
            token: value.token,
            oldfolderName: currentFocusFolder.name,
            newFolderName: renameFolderName,
            path: currentFocusFolder.path,
          })
          .then((res) => {
            console.log(res.data);
            if (res.data.status) {
              message.destroy();
              message.success("Folder Rename Success", 3);

              value.setfileFolderData([...tempArr, temp]);
              setVisibleRenameFolder(false);
              setRenameFolderName("");
            } else {
              message.destroy();
              message.error("Folder Rename Failed", 3);
            }
          });
      }
      console.log(x);
    } else {
      return message.error("Folder name should be between 0-30 characters");
    }
  };

  const downloadClick = (item) => {
    console.log(item);
    setDownloadProgressVisible(true);
    setDownloadingFile(item.name);

    axios({
      method: "get",
      url: value.url + "file/download-file",
      responseType: "blob",
      params: {
        path: item.path,
        //username: "karthik",
        email: value.email,
        token: value.token,
        name: item.name,
      },
      onDownloadProgress: (progressEvent) => {
        console.log(progressEvent);
        let percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setTotalFileSize(progressEvent.total / 10 ** 6);
        setDownloadProgress(percentCompleted);
        // console.log(progressEvent.lengthComputable);
        console.log(percentCompleted);
      },
    })
      .then((res) => {
        if (res.data && res.data.status && !res.data.status) {
          message.destroy();
          message.error("File Download Error", 3);
          setDownloadProgress(0);
          setTimeout(() => {
            setDownloadProgressVisible(false);
            setTotalFileSize(0);
            setDownloadingFile("");
            setDownloadFinished(false);
          }, 5000);
        } else {
          console.log(res);
          setTimeout(() => {
            setDownloadFinished(true);
          }, 500);

          FileDownload(res.data, item.name);
          setTimeout(() => {
            setDownloadProgress(0);
            setDownloadProgressVisible(false);
            setTotalFileSize(0);
            setDownloadingFile("");
            setDownloadFinished(false);
          }, 5000);
        }
      })
      .catch((error) => {
        if (error) {
          console.log(error);
        }
      });
  };

  const viewPlayHandler = (item, file_type) => {
    console.log(file_type);
    if (["image", "audio", "video", "pdf"].indexOf(file_type) >= 0) {
      message.destroy();
      message.loading("Fetching File From Server", 0);
      axios({
        method: "get",
        url: value.url + "file/download-file",
        responseType: "blob",
        params: {
          path: item.path,
          //username: "karthik",
          email: value.email,
          token: value.token,
          name: item.name,
        },
      })
        .then((res) => {
          message.destroy();
          if (res.data && res.data.status && !res.data.status) {
            message.error("File Fetch Error", 3);
          } else {
            setTimeout(() => {
              setDownloadFinished(true);
            }, 500);

            const file = new Blob([res.data], {
              type: `application/${file_type}`,
            });
            console.log(URL.createObjectURL(file));
            //Build a URL from the file
            let fileUrl = URL.createObjectURL(file);

            // FileDownload(res.data, item.name);

            if (file_type == "pdf") {
              setPdfUrl(fileUrl);
              setPdfEnable(true);
            } else if (file_type == "video") {
              setVideoEnable(true);
              setVideoUrl(fileUrl);
            } else if (file_type == "audio") {
              setAudioEnable(true);
              setAudioUrl(fileUrl);
            } else if (file_type == "image") {
              setImageEnable(true);
              setImageList([{ src: fileUrl }, ...imageList]);
            }
            setTimeout(() => {
              // setDownloadProgress(0);
              // setDownloadProgressVisible(false);
              // setTotalFileSize(0);
              // setDownloadingFile("");
              // setDownloadFinished(false);
            }, 5000);
          }
        })
        .catch((error) => {
          if (error) {
            console.log(error);
          }
        });
    } else {
      message.warning(<b>This file format isn't supported for WebView</b>, 3);
    }
  };

  const zipDownloadHandler = () => {
    console.log(currentFocusFolder);
    setZipDownload(true);
    setDownloadProgressVisible(true);
    setDownloadingFile(currentFocusFolder.name);
    axios({
      method: "get",
      url: value.url + "folder/download-zip",
      responseType: "blob",
      params: {
        path: currentFocusFolder.path,
        //username: "karthik",
        email: value.email,
        token: value.token,
        name: currentFocusFolder.name,
      },
      onDownloadProgress: (progressEvent) => {
        console.log(progressEvent);
        let percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setTotalFileSize(progressEvent.total / 10 ** 6);
        // setDownloadProgress(percentCompleted);
        // console.log(progressEvent.lengthComputable);
        console.log(percentCompleted);
      },
    })
      .then((res) => {
        console.log(res);
        setTimeout(() => {
          setDownloadFinished(true);
        }, 500);

        FileDownload(res.data, `${currentFocusFolder.name}.zip`);
        setTimeout(() => {
          setDownloadProgress(0);
          setDownloadProgressVisible(false);
          setTotalFileSize(0);
          setDownloadingFile("");
          setDownloadFinished(false);
        }, 5000);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.responderEnd);
        }
      });
  };

  const shareFileHandler = (e) => {
    e.preventDefault();
    if (emailRegex.test(fileRecipient)) {
      // console.log(currentFile);

      //code for testing if the newly adding user already exists in the access list
      let tempArr = [...currentFileAccessList];
      let test = tempArr.filter((item) => {
        return item.username === fileRecipient;
      });
      // console.log(test);
      if (fileRecipient.length > 0) {
        if (test.length == 0) {
          message.destroy();
          message.loading("Encrypting and granting the file access", 0);
          axios
            .post(value.url + "file/share-file", {
              //username: "karthik",
              email: value.email,
              token: value.token,
              path: currentFile.path,
              name: currentFile.name,
              folder: currentFile.folder,
            })
            .then((res) => {
              if (res.data.status) {
                message.destroy();
                message.success("Access Granted Successfully", 3);
                setShareableGxLink(res.data.payload.link);
                setcurrentFileAccessList([
                  ...currentFileAccessList,
                  ...res.data.payload.userlist,
                ]);
                setFileRecipient("");
              }
            });
        } else {
          message.destroy();
          message.warning("Recipient Already Exists");
          setFileRecipient("");
        }
      } else {
        message.destroy();
        message.warning("Recipient cannot be empty", 3);
      }
    } else {
      message.destroy();
      message.error("Please enter a valid email id", 3);
    }
  };

  const UpdatePublicLink = () => {
    message.destroy();
    message.loading("Encrypting and creating link", 0);
    axios
      .post(value.url + "file/update-shared-file", {
        _id: publicLinkObj._id,
        email: value.email,
        token: value.token,
        link: customLink ? customLink : publicLinkObj.link,
        secret: filePassword ? filePassword : publicLinkObj.secret,
        end_time: fileExpiry
          ? Date.now() + fileExpiry * 24 * 60 * 60 * 1000
          : publicLinkObj.end_time,
        remove_secret: removeSecret,
      })
      .then((res) => {
        if (res.data.status) {
          message.destroy();
          message.success("Public Link Updated Successfully");
          setShareablePublicLink(res.data.payload.full_link);
          setVisibleShareFile(false);
          setEditClicked(false);
          setremoveSecret(false);
        }
      });
  };

  const shareFileHandlerPublic = () => {
    message.destroy();
    message.loading("Encrypting and creating link", 0);
    axios
      .post(value.url + "file/share-file", {
        //username: "karthik",
        email: value.email,
        token: value.token,
        path: currentFile.path,
        name: currentFile.name,
        folder: currentFile.folder,
        custom_link: customLink ? customLink : null,
        secret: filePassword ? filePassword : null,
        end_time: fileExpiry
          ? Date.now() + fileExpiry * 24 * 60 * 60 * 1000
          : 0,
      })
      .then((res) => {
        if (res.data.status) {
          message.destroy();
          message.success("Public Link Generated Successfully");
          setShareablePublicLink(res.data.payload);
          // setVisibleShareFile(false);
        }
      });
  };

  const handleShare = (item) => {
    axios
      .post(value.url + "file/get-my-subdomain", {
        email: localStorage.getItem("user_account"),
        token: localStorage.getItem("token"),
      })
      .then((res) => {
        if (res.data.status) {
          message.destroy();
          setShareablePublicLink("");
          message.loading(
            <span style={{ fontWeight: "bold" }}>
              Fetching Shared File Details
            </span>,
            0
          );
          axios
            .post(
              value.url + "file/file-access-list",
              {
                //username: "karthik",
                email: value.email,
                token: value.token,
                path: item.path,
                name: item.name,
                folder: item.folder,
                withingx: true,
              }
              // {
              //   headers: {
              //     inf: "6ed22891bac6a1abd3f277c8cc97f170",
              //     mbid: "13d88b72d05effdc99a79bfe6c8ca589",
              //     mbuserid: "d9d575a688b5db6ed62632bde99bf4e3",
              //   },
              // }
            )
            .then((res) => {
              message.destroy();
              setCurrentFile(item);
              setResponse(res.data);
              setVisibleShareFile(true);
              if (res.data.status === true) {
                message.destroy();

                setPublicLinkObj(res.data.payload);
                // setDirectFileLink(res.data.payload.full_link);
                const link =
                  res.data.payload.full_link.split("/")[0] +
                  "/#/" +
                  res.data.payload.full_link.split("/")[1];
                console.log("linkkk", link);
                setShareablePublicLink(link);
              }
              console.log("kjbqedkeqwd", res.data);
            });
        } else {
          history.push("/subdomain");
        }
      });
  };

  const revokeFileAccess = (item) => {
    // console.log(currentShareableLink);
    console.log(currentFile);
    message.loading("Revoking Access", 0);
    let tempArr = [...currentFileAccessList];
    let newArr = tempArr.filter((obj) => {
      return obj.username !== item.username;
    });
    axios
      .post(value.url + "file/revoke-file-access", {
        //username: "karthik",
        email: value.email,
        token: value.token,
        path: currentFile.path,
        name: currentFile.name,
        folder: currentFile.folder,
        revoke_from: item.username,
        withingx: true,
      })
      .then((res) => {
        if (res.data.status) {
          console.log(res.data);
          message.destroy();
          message.success("Access Revoke Success");
          setcurrentFileAccessList([...newArr]);
        } else {
          message.destroy();
          message.error("Revoke Access Error", 3);
        }
      });
  };

  const foldermenu = (
    <Menu>
      <Menu.Item
        key="2"
        style={{ display: "flex", alignItems: "center" }}
        onClick={zipDownloadHandler}
      >
        <Icon style={{ marginTop: "9px" }} type="cloud-download" />
        &nbsp;Zip & Download
      </Menu.Item>
      <Menu.Item
        key="3"
        style={{ display: "flex", alignItems: "center" }}
        onClick={(e) => setVisibleRenameFolder(true)}
      >
        <Icon style={{ marginTop: "9px" }} type="edit" />
        &nbsp;Rename
      </Menu.Item>
      <Menu.Item
        key="3"
        style={{ display: "flex", alignItems: "center" }}
        // onClick={deleteFolderHandler}
        onClick={(e) => setDeleteConfirmation(true)}
      >
        <Icon style={{ marginTop: "9px" }} type="delete" />
        &nbsp;Delete
      </Menu.Item>
    </Menu>
  );

  const searchResults = (
    <div style={{ width: "75vw" }}>
      {query.length >= 1 ? (
        filteredFiles.length > 0 ? (
          filteredFiles.map((item) => {
            return (
              <>
                <Row gutter={16}>
                  <div
                    // onClick={e => searchClick(item)}
                    style={{
                      fontSize: "20px",
                      padding: "10px",
                    }}
                  >
                    <Col span={11}>
                      <img
                        style={{ marginTop: "-5px" }}
                        src={require(`../img/file-types/${item.file_type}.png`)}
                        alt=""
                        width="20px"
                      />
                      &nbsp;&nbsp;{item.name}
                    </Col>
                    <Col span={9}>
                      <span
                        style={{
                          fontSize: "18px",
                          color: "gray",
                        }}
                      >
                        {item.path}
                      </span>
                    </Col>
                    <Col span={4}>
                      <div
                        style={{ float: "right", cursor: "pointer" }}
                        onClick={(e) => downloadClick(item)}
                      >
                        <Tooltip placement="bottom" title={"Download File"}>
                          <Icon
                            type="cloud-download"
                            style={{
                              color: "#45A8E9",
                              marginTop: "5px",
                              marginRight: "10px",
                              fontSize: "25px",
                            }}
                          />
                        </Tooltip>
                        {/* <Divider
                      type="vertical"
                      style={{
                        fontWeight: "bold",
                        fontSize: "30px",
                        margin: "0px"
                      }}
                    /> */}
                        {/* <Tooltip placement="bottom" title={"Go to file location"}>
                      <Icon
                        type="login"
                        style={{
                          color: "#45A8E9",
                          marginTop: "5px",
                          marginLeft: "10px",
                          fontSize: "22px"
                        }}
                      />
                    </Tooltip> */}
                      </div>
                    </Col>
                  </div>
                </Row>
                <Divider style={{ margin: "0px", marginTop: "10px" }} />
              </>
            );
          })
        ) : (
          <div
            style={{
              padding: "45px",
              fontSize: "50px",
              color: "gray",
              margin: "40px 0px",
            }}
          >
            ☹️ File Not Found
          </div>
        )
      ) : (
        <div
          style={{
            padding: "45px",
            fontSize: "50px",
            color: "gray",
            margin: "40px 0px",
          }}
        >
          Type something to search...
        </div>
      )}
    </div>
  );

  useEffect(() => {
    // console.log(value.fileFolderData);
    setRootFolderList(value.fileFolderData);
  }, [value.fileFolderData]);

  const createFolder = () => {
    if (newFolderName.length <= 30 && newFolderName.length > 0) {
      message.destroy();
      message.loading("Creating Folder In Progess", 0);
      console.log(value.fileFolderData);
      let temp = {
        path: value.path,
        name: newFolderName,
        folder: true,
      };
      console.log(temp);

      let requiredList = value.fileFolderData.filter((item) => {
        return item.path === "root/";
      });

      let x = requiredList.find((o) => o.name == newFolderName);
      console.log(x);

      if (x !== undefined) {
        if (
          x.name !== temp.name &&
          x.path !== temp.path &&
          x.folder !== temp.folder
        ) {
          axios
            .post(value.url + "folder/create-folder", {
              //username: "karthik",
              email: value.email,
              token: value.token,
              folderName: newFolderName,
              path: value.path,
            })
            .then((res) => {
              console.log(res.data);
              if (res.data.status) {
                message.destroy();
                message.success("Folder Creation Success", 3);
                value.setfileFolderData([...value.fileFolderData, temp]);
                console.log(newFolderName);
                setVisibleCreateFolder(false);
                setNewFolderName("");
              } else {
                message.destroy();
                message.error("Folder Creation Failed", 3);
              }
            });
        } else {
          message.destroy();
          message.warning("Folder Name Already Exists", 3);
        }
      } else {
        axios
          .post(value.url + "folder/create-folder", {
            //username: "karthik",
            email: value.email,
            token: value.token,
            folderName: newFolderName,
            path: value.path,
          })
          .then((res) => {
            console.log(res.data);
            if (res.data.status) {
              message.destroy();
              message.success("Folder Creation Success", 3);
              value.setfileFolderData([...value.fileFolderData, temp]);
              console.log(newFolderName);
              setVisibleCreateFolder(false);
              setNewFolderName("");
            } else {
              message.destroy();
              message.error("Folder Creation Failed", 3);
            }
          });
      }
      console.log(value.path);
    } else {
      message.warning("Folder name should be between 0-30 characters");
    }
  };

  const conditionalShareModal = () => {
    if (shareablePublicLink === "") {
      if (response.payload === "No data exists") {
        return (
          <>
            <label htmlFor="link">Create Custom Link</label>
            <Search
              id="link"
              value={customLink}
              onChange={(e) => setCustomLink(e.target.value)}
              onSearch={checkCustomLinkAvilability}
              placeholder="Custom Link"
              loading={loading}
              enterButton={"Check Availability"}
              style={{ margin: "10px 0px" }}
            />
            <label htmlFor="pass">Make the file Password Protected</label>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Input
                id="pass"
                disabled={removeSecret}
                placeholder="Add Password"
                style={{ margin: "10px 0px" }}
                value={filePassword}
                onChange={(e) => setFilePassword(e.target.value)}
              />
              &nbsp;
            </div>
            <label htmlFor="exp">Add File Expiry</label>
            <Input
              id="exp"
              value={fileExpiry}
              onChange={(e) => setFileExpiry(e.target.value)}
              placeholder="Will expire in how many days"
              suffix={"Days"}
              style={{ margin: "10px 0px" }}
            />
            <Button
              block
              type="primary"
              style={{ fontWeight: "bold", marginTop: "10px" }}
              onClick={shareFileHandlerPublic}
            >
              Generate Public Link
            </Button>
          </>
        );
      } else {
        if (!localStorage.getItem("quickImage")) {
          return (
            <h4>
              File with same name exists. Please rename the file to share.
            </h4>
          );
        }
      }
    } else {
      if (!editClicked) {
        return (
          <>
            <b>Shareable File Link</b>{" "}
            <div style={{ display: "flex" }}>
              <Paragraph
                copyable={{
                  text: shareablePublicLink,
                }}
              >
                {shareablePublicLink}
              </Paragraph>
              &nbsp;&nbsp;
              <Icon
                type="edit"
                onClick={(e) => {
                  setEditClicked(true);
                  setCustomLink("");
                  setFileExpiry("");
                  setFilePassword("");
                }}
                style={{ marginTop: "5px" }}
              />
            </div>
            {selectedItem.file_type === "image" ? (
              <>
                <div>
                  <b>Direct File Link</b>
                </div>
                <div style={{ display: "flex" }}>
                  <Paragraph
                    copyable={{
                      text:
                        "https://drivetest.globalxchange.io/gxsharepublic/?full_link=" +
                        shareablePublicLink.replace("/#/", "/"),
                    }}
                  >
                    {"https://drivetest.globalxchange.io/gxsharepublic/?full_link=" +
                      shareablePublicLink.replace("/#/", "/")}
                  </Paragraph>
                </div>
              </>
            ) : (
              ""
            )}
          </>
        );
      } else {
        return (
          <>
            <label htmlFor="link">Update Custom Link:</label>
            <Search
              id="link"
              value={customLink === "" ? publicLinkObj.link : customLink}
              onChange={(e) => setCustomLink(e.target.value)}
              onSearch={checkCustomLinkAvilability}
              placeholder="Custom Link"
              loading={loading}
              enterButton={"Check Availability"}
              style={{ margin: "10px 0px" }}
            />
            <label htmlFor="pass">Update File Password:</label>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Input
                id="pass"
                disabled={removeSecret}
                placeholder="Add Password"
                style={{ margin: "10px 0px" }}
                value={
                  filePassword === "" ? publicLinkObj.secret : filePassword
                }
                onChange={(e) => setFilePassword(e.target.value)}
              />
              &nbsp;
              {publicLinkObj.secret !== null ? (
                <Button type="danger" onClick={(e) => setremoveSecret(true)}>
                  Remove Secret
                </Button>
              ) : (
                ""
              )}
            </div>
            <label htmlFor="exp">Update File Expiry:</label>
            <Input
              id="exp"
              value={fileExpiry}
              onChange={(e) => setFileExpiry(e.target.value)}
              placeholder="Will expire in how many days"
              suffix={"Days"}
              style={{ margin: "10px 0px" }}
              defaultValue={fileExpiry === "" ? publicLinkObj.end_date : ""}
            />

            <>
              <Button
                block
                style={{ fontWeight: "bold", marginTop: "10px" }}
                onClick={UpdatePublicLink}
              >
                Update publicly available link
              </Button>
            </>
          </>
        );
      }
    }
  };

  return (
    <>
      {value.path.length > 5 ? (
        <InFolder />
      ) : (
        <>
          {/* search Div */}
          <div>
            <Popover
              content={searchResults}
              // title="Title"
              trigger="focus"
              style={{ backgroundColor: "#f5f5f" }}
            >
              <Input
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                size="large"
                className="roundsearch"
                placeholder="Search Everything..."
                suffix={
                  <Icon
                    type="search"
                    style={{
                      fontSize: "15px",
                      color: "lightgray",
                    }}
                  />
                }
              />
            </Popover>
          </div>

          {/* Folders Div */}
          <div style={{ marginTop: "20px" }}>
            <p
              style={{
                fontSize: "20px",
                fontWeight: "bold",
              }}
            >
              Folders
            </p>
            <Row
              gutter={16}
              style={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
              }}
            >
              {rootFolderList.map((item) => {
                {
                  return item.path === "root/" && item.folder ? (
                    <Col xs={12} sm={4}>
                      <Dropdown overlay={foldermenu} trigger={["contextMenu"]}>
                        <div
                          onContextMenu={(e) => {
                            setCurrentFocusFolder({ ...item });
                            // console.log(item);
                          }}
                          onClick={(e) => {
                            // console.log(value.path + `${item.name}/`);

                            if (value.path == "/") {
                              value.setPath(`${item.name}/`);
                              value.setPathArray([
                                {
                                  name: item.name,
                                  path: item.path,
                                  folder: item.folder,
                                },
                              ]);
                            } else {
                              value.setPath(value.path + `${item.name}/`);
                              value.setPathArray([
                                ...value.pathArray,
                                {
                                  name: item.name,
                                  path: item.path,
                                  folder: item.folder,
                                },
                              ]);
                            }
                          }}
                          //   onContextMenu={e => setGlobalMenuVisible(true)}
                          style={{
                            borderRadius: "8px",
                            marginBottom: "5px",
                            cursor: "pointer",
                            width: "150px",
                            height: "150px",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                          }}
                          className="pop bg"
                        >
                          <span
                            style={{
                              fontSize: "15px",
                              fontWeight: "600",
                              color: "white",
                              padding: "0px 15px",
                              textAlign: "center",
                            }}
                          >
                            {item.name}
                          </span>
                        </div>
                      </Dropdown>
                    </Col>
                  ) : null;
                }
              })}
              <Col
                xs={12}
                sm={4}
                style={{
                  display: "flex",
                  alignItems: "center",
                  // paddingTop: "40px",
                  paddingLeft: "20px",
                }}
              >
                <Tooltip placement="bottom" title={"New Folder"}>
                  <div
                    onClick={(e) => setVisibleCreateFolder(true)}
                    style={{
                      cursor: "pointer",
                      borderStyle: "dashed",
                      borderWidth: "2px",
                      borderColor: "lightgray",
                      borderRadius: "10px",
                      width: "110px",
                      height: "80px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Icon
                      type="plus"
                      style={{ fontSize: "25px", color: "lightgray" }}
                    />
                  </div>
                </Tooltip>
              </Col>
              <Col
                xs={12}
                sm={4}
                style={{
                  display: "flex",
                  alignItems: "center",
                  // paddingTop: "40px",
                  paddingLeft: "20px",
                }}
              >
                <Tooltip placement="bottom" title={"Upload File"}>
                  <div
                    onClick={(e) => setVisibleUpload(true)}
                    style={{
                      cursor: "pointer",
                      borderStyle: "dashed",
                      borderWidth: "2px",
                      borderColor: "lightgray",
                      borderRadius: "10px",
                      width: "110px",
                      height: "80px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Icon
                      type="cloud-upload"
                      style={{ fontSize: "25px", color: "lightgray" }}
                    />
                  </div>
                </Tooltip>
              </Col>
              <Col
                xs={12}
                sm={4}
                style={{
                  display: "flex",
                  alignItems: "center",
                  // paddingTop: "40px",
                  paddingLeft: "20px",
                }}
              >
                <Tooltip placement="bottom" title={"Quick Image Upload"}>
                  <div
                    // onClick={(e) => setVisibleUpload(true)}
                    style={{
                      cursor: "pointer",
                      // borderStyle: "dashed",
                      // borderWidth: "2px",
                      // borderColor: "lightgray",
                      // borderRadius: "10px",

                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {acceptedFiles.length === 0 ? (
                      <div
                        {...getRootProps({ className: "mydropzone" })}
                        style={{
                          // padding: "50px",
                          cursor: "pointer",
                          borderStyle: "dashed",
                          borderWidth: "2px",
                          borderColor: "lightgray",
                          borderRadius: "10px",
                          width: "110px",
                          height: "60px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <input
                          {...getInputProps()}
                          accept={
                            "image/bmp,image/gif, image/vnd.microsoft.icon, image/jpeg, image/png, image/tiff, image/svg+xml"
                          }
                        />
                        <Icon
                          type="file-image"
                          style={{ fontSize: "25px", color: "lightgray" }}
                        />
                        {/* <p style={{ fontWeight: "500" }}>
                        Drag 'n' drop a file here, or click to select a file
                      </p> */}
                      </div>
                    ) : !uploadFinished ? (
                      <Button
                        type="primary"
                        onClick={(e) => {
                          setQuick(true);
                          get_file_status();
                        }}
                      >
                        Upload
                      </Button>
                    ) : (
                      <Loader
                        type="Oval"
                        color="#00BFFF"
                        height={100}
                        width={100}
                      />
                    )}
                  </div>
                </Tooltip>
              </Col>
            </Row>
          </div>
          {/* Files Div */}
          <Divider />
          <p
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              // marginTop: "15px",
              marginBottom: "5px",
            }}
          >
            Files
          </p>
          <div style={{ marginBottom: "30px" }}>
            {rootFolderList.map((item) => {
              return item.path === "root/" && !item.folder ? (
                <Card
                  // style={{ borderRadius: "8px", margin: "10px 0px" }}
                  // bodyStyle={{ padding: "18px" }}
                  bodyStyle={{ padding: "20px" }}
                  className="shadow-sm"
                  style={{
                    borderRadius: "8px",
                    border: "none",
                    marginTop: "8px",
                    marginBottom: "10px",
                  }}
                >
                  <Row
                    style={{
                      display: "flex",
                      flexDirection: window.innerWidth > 600 ? "row" : "column",
                    }}
                  >
                    <Col
                      xs={24}
                      sm={16}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "15px",
                        fontWeight: "600",
                        // marginTop: "-9px"
                      }}
                    >
                      {item.file_type === "image" &&
                      !item.name.includes("svg") ? (
                        <img
                          src={`${value.url}file/download-file?path=${item.path}&email=${value.email}&token=${value.token}&name=${item.name} `}
                          width="25px"
                          alt=""
                        />
                      ) : (
                        <img
                          src={require(
                            `../img/file-types/${item.file_type}.png`
                          )}
                          width="25px"
                          alt=""
                        />
                      )}
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <span>{item.name}</span>
                    </Col>
                    {/* <Col
                  span={8}
                  style={{
                    textAlign: "center",
                    display: window.innerWidth > 600 ? true : "none"
                  }}
                >
                  {}
                </Col> */}
                    <Col
                      xs={24}
                      sm={8}
                      style={{ textAlign: "right", marginTop: "4px" }}
                    >
                      <Row
                        gutter={16}
                        style={{
                          marginTop: window.innerWidth > 600 ? "0x" : "15px",
                        }}
                      >
                        <Col
                          xs={4}
                          sm={9}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: "15.5px",
                            fontWeight: "500",
                            // justifyContent: "center"
                          }}
                        >
                          <i
                            class="fa fa-database"
                            style={{ color: "#757575" }}
                          ></i>
                          &nbsp;&nbsp;
                          {(item.file_size / 10 ** 6).toFixed(3) > 1000 ? (
                            <>
                              {" "}
                              {(item.file_size / 10 ** 9).toFixed(3)}&nbsp;GB
                            </>
                          ) : (item.file_size / 10 ** 6).toFixed(3) > 0.1 ? (
                            <>{(item.file_size / 10 ** 6).toFixed(3)}&nbsp;MB</>
                          ) : (
                            <>{(item.file_size / 10 ** 3).toFixed(3)}&nbsp;KB</>
                          )}
                        </Col>
                        <Col xs={20} sm={15}>
                          <Tooltip placement="top" title={"View | Play"}>
                            <Button
                              onClick={(e) =>
                                viewPlayHandler(item, item.file_type)
                              }
                              size="small"
                              style={{ borderStyle: "none" }}
                            >
                              <Icon
                                type="play-circle"
                                style={{ fontSize: "18px" }}
                              />
                            </Button>
                          </Tooltip>
                          <Tooltip placement="top" title={"Download"}>
                            <Button
                              onClick={(e) => downloadClick(item)}
                              size="small"
                              style={{ borderStyle: "none" }}
                            >
                              <Icon
                                type="cloud-download"
                                style={{ fontSize: "18px" }}
                              />
                            </Button>
                          </Tooltip>
                          <Tooltip placement="top" title={"Share"}>
                            <Button
                              style={{ borderStyle: "none" }}
                              size="small"
                              onClick={(e) => {
                                handleShare(item);
                                setSelectedItem(item);
                              }}
                            >
                              <Icon
                                type="share-alt"
                                style={{ fontSize: "18px" }}
                              />
                            </Button>
                          </Tooltip>
                          {/* <Tooltip placement="top" title={"Commit"}>
                          <Button
                            size="small"
                            style={{
                              borderStyle: "none"
                            }}
                          >
                            <Icon type="github" style={{ fontSize: "18px" }} />
                          </Button>
                        </Tooltip> */}
                          <Tooltip placement="top" title={"Delete"}>
                            <Button
                              size="small"
                              style={{
                                borderStyle: "none",
                              }}
                              // onClick={e => deleteFileHandler(item)}
                              onClick={(e) => {
                                setDeleteFileConfirmation(true);
                                setFileTobeDeleted(item);
                              }}
                            >
                              <Icon
                                type="delete"
                                style={{ fontSize: "18px", color: "#f44336" }}
                              />
                            </Button>
                          </Tooltip>

                          {/* <Tooltip placement="top" title={"File Info"}>
                        <Button
                          size="small"
                          style={{
                            borderStyle: "none"
                          }}
                        >
                          <Icon
                            type="info-circle"
                            style={{ fontSize: "18px" }}
                          />
                          <i
                            class="fas fa-info-circle"
                            style={{ fontSize: "18px" }}
                          ></i>
                        </Button>
                      </Tooltip> */}

                          {/* <div style={{ marginTop: "8px" }}>
                        <Row gutter={16}>
                          <Col span={12}>
                            {" "}
                            <Button
                              size="small"
                              block
                              onClick={e => {
                                setVisibleShareFile(true);
                                setCurrentFile(item);
                              }}
                            >
                              <Icon
                                type="share-alt"
                                style={{ fontWeight: "900" }}
                              />{" "}
                              <b>Share</b>
                            </Button>
                          </Col>
                          <Col span={12}>
                            {" "}
                            <Button block size="small">
                              <Icon type="github" /> <b>Commit</b>
                            </Button>
                          </Col>
                        </Row>
                      </div>
                  */}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              ) : (
                ""
              );
            })}
          </div>
        </>
      )}

      {/* Create Folder Modal */}
      <Modal
        width={350}
        bodyStyle={{ padding: "30px" }}
        closable={false}
        // title="Basic Modal"
        footer={null}
        visible={visibleCreateFolder}
        onCancel={(e) => setVisibleCreateFolder(false)}
      >
        <form onSubmit={createFolder} style={{ textAlign: "center" }}>
          <Input
            autoFocus
            type="text"
            size="large"
            placeholder="Type folder name"
            value={newFolderName}
            onChange={(e) =>
              setNewFolderName(e.target.value.replace(/[^0-9a-zA-Z-_]/g, ""))
            }
          />

          <Button
            type="primary"
            block
            style={{
              background: "#B3315B",
              borderColor: "#B3315B",
              marginTop: "20px",
            }}
            onClick={createFolder}
          >
            Create Folder
          </Button>

          <div
            style={{
              marginTop: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Icon type="exclamation-circle" style={{ color: "orange" }} />

            <small style={{ paddingLeft: "5px" }}>
              Folder name should be between 0-30 characters
            </small>
          </div>
        </form>
      </Modal>

      {/* Rename Folder Modal*/}

      <Modal
        width={350}
        bodyStyle={{ padding: "30px" }}
        closable={false}
        // title="Basic Modal"
        footer={null}
        visible={visibleRenameFolder}
        onCancel={(e) => setVisibleRenameFolder(false)}
      >
        <div
          style={{ fontSize: "15px", fontWeight: "bold", marginBottom: "5px" }}
        >
          Existing Name: {currentFocusFolder.name}
        </div>

        <form onSubmit={renameFolder} style={{ textAlign: "center" }}>
          <Input
            autoFocus
            type="text"
            size="large"
            placeholder="Type the new name"
            value={renameFolderName}
            onChange={(e) =>
              setRenameFolderName(e.target.value.replace(/[^0-9a-zA-Z-_]/g, ""))
            }
          />

          <Button
            type="primary"
            block
            style={{ marginTop: "20px" }}
            onClick={renameFolder}
          >
            Rename Folder
          </Button>

          <div
            style={{
              marginTop: "10px",
              display: "flex",
              alignItems: "center",
              // justifyContent: "center"
              textAlign: "left",
            }}
          >
            <Icon
              type="exclamation-circle"
              style={{ color: "orange", marginTop: "9px" }}
            />
            &nbsp;&nbsp;
            <small>
              Folder name should be less than 30 characters & unique in current
              directory
            </small>
          </div>
        </form>
      </Modal>

      {/* Upload Modal */}
      <Modal
        destroyOnClose
        footer={null}
        title="Upload File"
        visible={visibleUpload}
        // closable={!uploadStarted}
        // onOk={this.handleOk}
        onCancel={(e) => {
          if (!uploadStarted) {
            setVisibleUpload(false);
            removeAll();
          }
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {!uploadStarted ? (
            <>
              <div
                {...getRootProps({ className: "mydropzone" })}
                style={{
                  padding: "50px",
                  borderStyle: "dashed",
                  borderRadius: "10px",
                  borderWidth: "2px",
                  borderColor: "lightgray",
                  textAlign: "center",
                }}
              >
                <input {...getInputProps()} />
                <Icon
                  type="inbox"
                  style={{ fontSize: "70px", color: "lightgray" }}
                />
                <p style={{ fontWeight: "500" }}>
                  Drag 'n' drop a file here, or click to select a file
                </p>
              </div>
              <div style={{ marginTop: "10px", marginLeft: "10px" }}>
                {/* <h4>Files</h4> */}
                {files}
              </div>
              <Button
                disabled={acceptedFiles.length === 0}
                block
                type="primary"
                style={{
                  background: "#B3315B",
                  borderColor: "#B3315B",
                  marginTop: "15px",
                  color: "white",
                }}
                // onClick={checkFilename}
                onClick={get_file_status}
              >
                UPLOAD FILE
              </Button>{" "}
            </>
          ) : !uploadFinished ? (
            <>
              <Loader type="Oval" color="#00BFFF" height={100} width={100} />
              <span
                style={{
                  marginTop: "20px",
                  fontSize: "18px",
                  fontWeight: "500",
                }}
              >
                Uploading
              </span>
              {files}
            </>
          ) : (
            <Progress type="circle" percent={100} />
          )}
        </div>
      </Modal>

      {/* Download Modal */}
      <Modal
        destroyOnClose
        footer={null}
        visible={downloadProgressVisible}
        // onOk={this.handleOk}
        onCancel={(e) => {
          setDownloadProgressVisible(false);
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {zipDownload ? (
            <>
              {!downloadFinished ? (
                <>
                  <Loader
                    type="Oval"
                    color="#00BFFF"
                    height={100}
                    width={100}
                  />
                  <span
                    style={{
                      marginTop: "20px",
                      fontSize: "18px",
                      fontWeight: "500",
                    }}
                  >
                    Zipping & Downloading
                  </span>
                </>
              ) : (
                ""
              )}

              <span
                style={{
                  marginTop: "20px",
                  fontSize: "18px",
                  fontWeight: "500",
                }}
              >
                Name: {downloadingFile}
              </span>
            </>
          ) : (
            <>
              {" "}
              <Progress type="circle" percent={downloadProgress} />
              <span
                style={{
                  marginTop: "20px",
                  fontSize: "18px",
                  fontWeight: "500",
                }}
              >
                Name: {downloadingFile}
              </span>
              <span
                style={{
                  marginTop: "5px",
                  fontSize: "18px",
                  fontWeight: "500",
                }}
              >
                Size: {totalFileSize.toFixed(3)}MB{" "}
                {/* <small>
              <i>approximately</i>
            </small> */}
              </span>
            </>
          )}

          {downloadFinished ? (
            <span
              style={{ marginTop: "20px", fontSize: "15px", fontWeight: "500" }}
            >
              Saving file... Check Downloads section in browser.
            </span>
          ) : (
            ""
          )}
        </div>
      </Modal>

      {/* Sharefile Modal */}
      <Modal
        width={500}
        bodyStyle={{ padding: "30px" }}
        closable={false}
        // title="Basic Modal"
        footer={null}
        visible={visibleShareFile}
        onCancel={(e) => {
          setVisibleShareFile(false);
          setTimeout(() => {
            setCustomLink("");
            setFilePassword("");
            setFileExpiry("");
            setCurrentFile({});
            setShareablePublicLink("");
            setEditClicked(false);
            localStorage.removeItem("quickImage");
          }, 300);
        }}
      >
        {conditionalShareModal()}
      </Modal>

      {/* show quick link */}
      {/* <Modal
        width={500}
        bodyStyle={{ padding: "30px" }}
        closable={false}
        // title="Basic Modal"
        footer={null}
        visible={visibleQuickShareFile}
        onCancel={(e) => {
          setVisibleQuickShareFile(false);
        }}
      >
        {quickItem.file_type === "image" && !item.name.includes("svg") ? (
          <img
            src={`${value.url}file/download-file?path=${item.path}&email=${value.email}&token=${value.token}&name=${item.name} `}
            width="25px"
            alt=""
          />
        ) : (
          <img
            src={require(`../img/file-types/${item.file_type}.png`)}
            width="25px"
            alt=""
          />
        )}
      </Modal> */}

      {/* Delete Folder Confirmation Modal */}
      <Modal
        width={400}
        // title="Basic Modal"
        visible={deleteConfirmation}
        onOk={deleteFolderHandler}
        onCancel={(e) => setDeleteConfirmation(false)}
      >
        <Row>
          <Col span={2}>
            <Icon
              type="delete"
              theme="filled"
              style={{ color: "red", fontSize: "20px" }}
            />
          </Col>
          <Col span={22}>
            <h6>Do you want to delete this folder?</h6>
            <p>
              When clicked the OK button, this folder will be deleted. Press
              Cancel to abort the operation.
            </p>
          </Col>
        </Row>
      </Modal>

      {/* Delete File Confirmation Modal */}
      <Modal
        width={400}
        // title="Basic Modal"
        footer={null}
        visible={deleteFileConfirmation}
        // onOk={deleteFileHandler}
        onCancel={(e) => setDeleteFileConfirmation(false)}
      >
        <Row>
          <Col span={2}>
            <Icon
              type="delete"
              theme="filled"
              style={{ color: "red", fontSize: "20px" }}
            />
          </Col>
          <Col span={22}>
            <h4>Do you want to delete this file?</h4>
            <p>
              Select whether to just Delete the File or Delete the File and
              Revoke Sharing Access as well.
            </p>
          </Col>
        </Row>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {fileTobeDeleted.file_type !== "video" ? (
            <Button onClick={deleteFileHandler}>Delete File</Button>
          ) : (
            ""
          )}
          &nbsp;&nbsp;
          <Button type="primary" ghost onClick={deleteFileStopSharing}>
            Delete File and Stop Sharing
          </Button>
        </div>
      </Modal>

      {/* Video Play Modal */}
      <Modal
        centered
        closable={false}
        // title="Basic Modal"
        visible={videoEnable}
        onCancel={(e) => setVideoEnable(false)}
        footer={null}
        bodyStyle={{ padding: "0px" }}
      >
        <ReactPlayer
          // width="auto"
          // height="auto"
          width="100%"
          height="100%"
          url={videoUrl}
          playing={videoEnable}
          controls={true}
        />
      </Modal>

      {/* Audio Play Modal */}
      <Modal
        centered
        closable={false}
        // title="Basic Modal"
        visible={audioEnable}
        onCancel={(e) => setAudioEnable(false)}
        footer={null}
        bodyStyle={{ padding: "0px" }}
      >
        <ReactPlayer
          // width="auto"
          // height="auto"
          width="100%"
          height="40px"
          url={audioUrl}
          playing={audioEnable}
          controls={true}
        />
      </Modal>

      {/* Pdf View Modal */}
      <Drawer
        style={{ zIndex: "999999999999999999999999" }}
        width="100vw"
        // closable={true}
        // title="Basic Modal"
        visible={pdfEnable}
        onClose={(e) => setPdfEnable(false)}
        // footer={null}
        bodyStyle={{ padding: "0px" }}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button onClick={(e) => setZoomScale(zoomScale - 0.1)}>
            <Icon type="zoom-out" />
          </Button>
          &nbsp;&nbsp;
          <Button onClick={(e) => setZoomScale(zoomScale + 0.1)}>
            <Icon type="zoom-in" />
          </Button>
        </div>
        <PDFViewer
          document={{
            url: pdfUrl,
          }}
          scale={zoomScale}
        />
      </Drawer>

      {/* Multi Image share link */}
      <Modal
        width={600}
        bodyStyle={{ paddingLeft: "30px", paddingTop: "30px" }}
        closable={true}
        title={`Total ${multiImageLink.length} images uploaded.`}
        footer={null}
        visible={visibleMultiShareFile}
        onCancel={(e) => {
          setVisibleMultiShareFile(false);
          localStorage.removeItem("quickImage");
          setMultiImageLink([]);
          setQuick(false);
        }}
      >
        <div style={{ overflowY: "scroll", height: "500px" }}>
          {multiImageLink
            ? multiImageLink.map((item, index) => {
                return (
                  <>
                    <b>File {index + 1} Public Link:</b>{" "}
                    <div style={{ display: "flex" }}>
                      <Paragraph
                        copyable={{
                          text: item,
                        }}
                      >
                        {item}
                      </Paragraph>
                      {/* &nbsp;&nbsp;
                    <Icon
                      type="edit"
                      onClick={(e) => {
                        setEditClicked(true);
                        setCustomLink("");
                        setFileExpiry("");
                        setFilePassword("");
                      }}
                      style={{ marginTop: "5px" }}
                    /> */}
                    </div>
                    <div>
                      <b>File {index + 1} Direct Link:</b>
                    </div>
                    <div style={{ display: "flex" }}>
                      <Paragraph
                        copyable={{
                          text:
                            "https://drivetest.globalxchange.io/gxsharepublic/?full_link=" +
                            item.replace("/#/", "/"),
                        }}
                      >
                        {"https://drivetest.globalxchange.io/gxsharepublic/?full_link=" +
                          item.replace("/#/", "/")}
                      </Paragraph>
                    </div>
                    <Divider />
                  </>
                );
              })
            : ""}
        </div>
      </Modal>

      {/* Image Viewer Modal */}
      <Viewer
        visible={imageEnable}
        onClose={() => {
          setImageEnable(false);
        }}
        images={imageList}
      />
    </>
  );
};

export default ContentArea;
